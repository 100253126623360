import React, { useState } from 'react';
import '../assets/css/common/index.module.scss';
import Styles from '../assets/css/layouts/layout.module.scss';
import Navbar from './Navbar';
import Content from './Content';
import Sidebar from './Sidebar';
import PopUpMessage from '../utils/errors/PopUpMessage';

const Layout = (props) => {

  const [sidebarOpen, setSidebarOpen] = useState(true);

  // const [sidebar, setSidebar] = useState()
    
  const handleToggle = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div className={`${Styles.layout}`}>
        <Navbar onToggle={handleToggle}/>
        <Sidebar isOpen={sidebarOpen} sidebarData="artist"/>
        <Content />
        <PopUpMessage />
    </div>
  )
}

export default Layout
