import React, { useEffect, useState } from "react";
import Styles from "../../assets/css/components/artistPlaylist/playlistAdd.module.scss";
import { useNavigate } from "react-router-dom";
import { RiArrowLeftSLine } from "react-icons/ri";
import axios from 'axios';
import { baseUrl } from "../../utils/constant";
import { useDispatch } from "react-redux";
import { popUpBoxTrigger } from "../../features/message/popUpSlice";
import { AiOutlineLoading3Quarters } from "react-icons/ai";


const PlaylistAdd = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [category, setCategory] = useState([]);
  const [language, setLanguage] = useState([]);
  const [loading, setLoading] = useState(false);

  const [formData,setFormData] = useState({
    title: "",
    description: "",
    release_date: "",
    genre: "",
    language: "",
    status: 1,
    profileImage: null,
    profileImageName: "",
    coverImage: null,
    coverImageName: ""
  });

  
  const [error, setError] = useState({
    title: false,
    description: false,
    release_date: false,
    genre: false,
    language: false,
    status: false,
    coverImage: false,
    profileImage: false
  });


  useEffect(() => {
    const token = localStorage.getItem("token");
    const getCategory = async () => {

      
      try {
        const getAllCategory = await axios.get(`${baseUrl}artist/list-all-category`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });
        const result = getAllCategory;
        console.log(result);
        if(result.data.status === 200){
          setCategory(result.data.data.category);
        }
      } catch (error) {
        console.log("Error : ", error);
      }

      try {
        const getAllLanguages = await axios.get(`${baseUrl}artist/language/list`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });
        const result = getAllLanguages;
        console.log(result);
        if(result.data.status === 200){
          setLanguage(result.data.data.languages);
        }
      } catch (error) {
        console.log("Error : ", error);
      }

    }
    getCategory();
  }, []);


  const handleCoverImageChange = (e) => {
    const file = e.target.files[0];
    if (file && (file.type === 'image/png' || file.type === 'image/jpeg')) {
      setFormData({...formData, coverImage: file, coverImageName: file.name});
    } else {
      setFormData({...formData, coverImage: null, coverImageName: ""});
      dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "Please select a valid image file (PNG or JPG)."}));
    }
  };

  const handleProfilePictureChange = (e) => {
    const file = e.target.files[0];
    if (file && (file.type === 'image/png' || file.type === 'image/jpeg')) {
      setFormData({...formData, profileImage: file, profileImageName: file.name});
    } else {
      setFormData({...formData, profileImage: null, profileImageName: ""});
      dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "Please select a valid image file (PNG or JPG)."}));
    }
  };


  const handleSubmit = async (e) => {

    e.preventDefault();
    setLoading(true);
    console.log("Playlist : ", formData);

    const userData = JSON.parse(localStorage.getItem("user_data"));


    const errorData = {
      title: false,
      description: false,
      release_date: false,
      genre: false,
      language: false,
      status: false,
      coverImage: false,
      profileImage: false
    }

    // if(formData.title === "") errorData.title = true;
    // if(formData.description === "") errorData.description = true;
    // if(formData.release_date === "") errorData.release_date = true;
    // if(formData.genre === "") errorData.genre = true;
    // if(formData.language === "") errorData.language = true;
    // if(formData.status === "") errorData.status = true;

    if(formData.title === "" || formData.description === "" || formData.release_date === "" || formData.genre === "" || formData.language === "" || formData.status === ""){
      dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "Please fill all the fields."}));
    }

    if (formData.profileImage === "" || formData.profileImageName === "") {
      errorData.profileImage = true;
      dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "Profile picture is required."}));
    }

    if (formData.coverImage === "" || formData.coverImageName === "") {
      errorData.coverImage = true;
      dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "Cover picture is required."}));
    }


    setError(errorData);
    if(errorData.title == true || errorData.description == true || errorData.release_date == true || errorData.genre == true || errorData.language == true || errorData.status == true || errorData.coverImage == true || errorData.profileImage == true ){
      setLoading(false);
      return;
    }

    const formDataSet = new FormData();
    formDataSet.append('title', formData.title);
    formDataSet.append('description', formData.description);
    formDataSet.append('release_date', formData.release_date);
    formDataSet.append('category_id', formData.genre);
    formDataSet.append('language_id', formData.language);
    formDataSet.append('status', formData.status);
    formDataSet.append('playlist_profile_image', formData.profileImage);
    formDataSet.append('playlist_cover_image', formData.coverImage);
    formDataSet.append('artist_id', userData.id);

    const token = localStorage.getItem("token");

    try{

      const data = await axios({
        method: 'POST',
        url: `${baseUrl}artist/artist-playlist/create`,
        data: formDataSet,
        headers: {
          'Authorization': 'Bearer ' + token
        }
      });

      const result = data;
      if(result.data.status === 200){
        setLoading(false);
        dispatch(popUpBoxTrigger({type: "success", heading: "Success", message: result.data.message}));
        navigate('/playlist');
      }else{
        console.log("Playlist Add Failed");
        dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "oops!! something went wrong"}));
        setLoading(false);
      }
    }catch (error){

      console.log(error);
      if(error.response.data.status === 422){
        dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: error.response.data.message}));
        setLoading(false);
        return;
      }

      if(error !== ""){
        dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "oops!! something went wrong"}));
        setLoading(false);
        return;
      }
    }
  };


  return (
    <div className={`${Styles.playlistAdd}`}>

        <div className={`${Styles.form_heading}`}>
          <h3>Create New Playlist</h3>
          <button onClick={() => navigate("/playlist/list")}>
            <RiArrowLeftSLine /> Go To Playlist List
          </button>
        </div>

        <div className={`${Styles.add_form}`}>
          <div className={`${Styles.form_grid}`}>

            <div className={`${Styles.form_field}`}>
              <label>Playlist Title</label>
              <input type="text" placeholder="Enter Playlist Title" onChange={(e)=> setFormData({...formData, title: e.target.value})} />
            </div>
            <div className={`${Styles.form_field}`}>
              <label>Playlist Description</label>
              <textarea type="text" placeholder="Enter Playlist Description" name="description" onChange={(e)=> setFormData({...formData, description: e.target.value})}/>
            </div>
          </div>


          <div className={`${Styles.form_grid}`}>
            <div className={`${Styles.form_field}`}>
              <label>Release Date</label>
              <input type="date" name="release_date" onChange={(e)=> setFormData({...formData, release_date: e.target.value})}/>
            </div>
            <div className={`${Styles.form_field}`}>
              <label>Genre</label>
              <select name="genre" onChange={(e)=> setFormData({...formData, genre: e.target.value})}>
                <option value="">***** Select Genre *****</option>
                {
                  category.map((data) => {
                    return (
                      <option value={data.id}>{data.name}</option>
                    )
                  })
                }
              </select>
            </div>
          </div>


          <div className={`${Styles.form_grid}`}>
            <div className={`${Styles.form_field}`}>
              <label>Language</label>
              <select name="language" onChange={(e)=> setFormData({...formData, language: e.target.value})}>
                <option value="">***** Select Language *****</option>
                {
                  language.map((data) => {
                    return (
                      <option value={data.id}>{data.name}</option>
                    )
                  })
                }
              </select>
            </div>
            <div className={`${Styles.form_field}`}>
              <label>Status</label>
              <select name="status" onChange={(e)=> setFormData({...formData, status: e.target.value})}>
                {/* <option value="">***** Select Status *****</option> */}
                <option value="1" checked>Publish</option>
                <option value="0">Unpublish</option>
              </select>
            </div>
          </div>


          <div className={`${Styles.form_grid}`}>
            <div className={`${Styles.form_field}`}>
              <label>Profile Picture</label>
              <div className={`${Styles.customFileInput}`}>
                {/* style={{borderColor: `${error.coverImage ? "red" : ""}`}} */}
                <input type="text" placeholder="Choose file..."  value={formData.profileImageName} readOnly />
                <input type="file" accept=".png,.jpg" id="file-input" name="ImageStyle" onChange={handleProfilePictureChange} />
              </div>
            </div>
            <div className={`${Styles.form_field}`}>
              <label>Cover Picture</label>
              <div className={`${Styles.customFileInput}`}>
                <input type="text" placeholder="Choose file..." value={formData.coverImageName} readOnly />
                <input type="file" accept=".png,.jpg" id="file-input" name="ImageStyle" onChange={handleCoverImageChange} />
              </div>
            </div>
          </div>


          <div className={`${Styles.playlist_btn}`}>
            <button className={`${Styles.create}`} disabled={loading ? "disabled" : ""} onClick={handleSubmit}>{loading ? <AiOutlineLoading3Quarters /> : "Create"}</button>
            <button className={`${Styles.cancel}`} onClick={() => navigate("/playlist/list")}>Cancel</button>
          </div>
        </div>
    </div>
  );
}

export default PlaylistAdd
