import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const AuthCheck = (props) => {

const Component = props.component;
const navigate = useNavigate();

useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("user_data"));
    if(!localStorage.getItem("token")){
        navigate("/login");
    }

    if(userData.approvalStatus == 0){
        navigate("/approvalPending");
    }

    if(userData.status == 0){
        navigate("/disableAccount");
    }
});

return <Component />
}

export default AuthCheck
