import React, { useEffect, useState } from 'react';
import Styles from '../../assets/css/track.module.scss';
import { IoMdClose } from "react-icons/io";
import { MdFilterListAlt } from "react-icons/md";
import { LuPlusCircle } from "react-icons/lu"; 
import { BsSortDown } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';
import { BiCheck } from "react-icons/bi";
import { FaRegHeart } from "react-icons/fa";
import { PiDotsThreeOutlineFill } from "react-icons/pi";
import ProfileLogo from "../../assets/images/Artist/Album/Track-image2.png";
import { LuClock7 } from "react-icons/lu";
import { MdDelete } from "react-icons/md";
import { FiEdit } from "react-icons/fi";
import Pagination from "../../components/common/Pagination";
import DeleteIllustration from "../../assets/images/deleteIllustrator.png";
import axios from "axios";
import { baseUrl } from '../../utils/constant';
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useDispatch } from 'react-redux';
import { popUpBoxTrigger } from '../../features/message/popUpSlice';
import { updatePage } from '../../features/pagination/paginationSlice';
import { convertDuration } from '../../utils/convertFunctions';
import { FaUsers } from "react-icons/fa";
import ContentLoaderGif from '../../assets/images/contentLoader.gif';

const Track = () => {

  const [isOpen, setIsOpen] = useState(false);
  const [isSortOpen, setIsSortOpen] = useState(false);
  const [contentLoader, setContentLoader] = useState('true');

  const [statusFilter, setStatusFilter] = useState('');
  const [filterDate, setFilterDate] = useState({
    from: '',
    to: ''
  });
  const [trackFilter, setTrackFilter] = useState({
    from: '',
    to: '',
    search: '',
    sorting: 'desc',
    page: "1",
    limit: "5"
  });

  const [pagination, setPagination] = useState({
    currentPage: 6,
    totalPage: 6,
    showing: 5
  });
  

  const dateStatusApply = () => {
    setTrackFilter({...trackFilter, from: filterDate.from, to: filterDate.to, status: statusFilter});
    setIsOpen(false);
  }

  const handleClear = () => {
    setFilterDate({ from: '', to: '' });
    setStatusFilter('');
  };

  const handleReset = () => {
    setFilterDate({ from: '', to: '' });
    setStatusFilter('');
    setTrackFilter({...trackFilter, from: "", to: "", status: ""});
    setIsOpen(false);
  };


  const [trackData, setTrackData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [deleteId, setDeleteId]= useState(null);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(1);

  const [dropDownTrackManage, setDropDownTrackManage] = useState({
    id: 0,
    open: false
  });
  const [deleteTrackDrop, setDeleteTrackDrop] = useState(false);

  const handleDropDown = (e) => {
    let value = e.currentTarget.dataset.trackId;
    value = Number(value);
    if(dropDownTrackManage.id === value){
      setDropDownTrackManage({...dropDownTrackManage, open: !(dropDownTrackManage.open)})
    }

    if(dropDownTrackManage.id !== value){
      setDropDownTrackManage({id: value, open: true});
    }
  }

  const changePage = (count, type) => {
    console.log(`final done ${count}`);

    if(type === "page"){
      setTrackFilter({...trackFilter, page: count});
    }

    if(type === "show"){
      setTrackFilter({...trackFilter, page: "1", limit: count});
      setPagination({...pagination, showing: count});
    }

  }


  useEffect(() => {

    const token = localStorage.getItem("token");
    const userData = JSON.parse(localStorage.getItem("user_data"));

    const getTrackData = async () => {
      try {
        const getTrackList = await axios.get(`${baseUrl}artist/music-track/list/${userData.id}?from=${filterDate.from}&to=${filterDate.to}&status=${statusFilter}&search=${trackFilter.search}&sort=${trackFilter.sorting}&page=${trackFilter.page}&limit=${trackFilter.limit}`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });
  
        const result = getTrackList;
        if(result.data.status === 200){
          setTrackData(result.data.data.music_track);
          setContentLoader(result.data.data.music_track.length !== 0 ? true : false);
          setPagination({
            ...pagination,
            currentPage: result.data.data.pagination.currentPage,
            totalPage: result.data.data.pagination.totalPage,
          });
        }
      } catch (error) {
        
        console.log("Error : ", error);
      }

    }
    getTrackData();
  }, [trackFilter, refresh]);



  const handleDeleteTrack = async () => {
    if(deleteId != null){

      try{
        const token = localStorage.getItem("token");
        const data = await axios({
          method: 'POST',
          url: `${baseUrl}artist/music-track/delete/${deleteId}`,
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });
  
        const result = data;
        if(result.data.status === 200){
  
          setLoading(false);
          setDeleteTrackDrop(false);
          dispatch(popUpBoxTrigger({type: "success", heading: "Success", message: result.data.message}));
          setDropDownTrackManage({...dropDownTrackManage, open: false});
          setRefresh(refresh+1);
        }else{
          dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "oops!! something went wrong"}));
          setLoading(false);
          setDeleteTrackDrop(false);
        }

      }catch (error){
  
        console.log(error);
        setDeleteTrackDrop(false);
        if(error.response.data.status === 422){
          dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: error.response.data.message}));
          setLoading(false);
          return;
        }
  
        console.log(error.response.data.errors.password);
  
        if("password" in error.response.data.errors){
          dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: error.response.data.errors.password[0]}));
          setLoading(false);
          return;
        }
      }


    }else{

    }
  }

  return (
    <div className={`${Styles.track}`}>

      {
        deleteTrackDrop && (
        <div className={`${Styles.deleteTrack}`}>
          <div className={`${Styles.innerDeleteTrack}`}>
              <div className={`${Styles.heading}`}>
                  <p>Do you really want to delete this track?</p>
              </div>

              <div className={`${Styles.delete_image}`}>
                  <img src={DeleteIllustration} alt="not supported" />
              </div>

              <div className={`${Styles.buttons}`}>
                  <button onClick={handleDeleteTrack}>{loading ? <AiOutlineLoading3Quarters /> : "Yes, Delete Track"}</button>
                  <button onClick={() => {setDeleteTrackDrop(false); setDropDownTrackManage({id: 0, open: false});}}>Skip & Continue</button>
              </div>
          </div>
        </div>
        )
      }

      <div className={`${Styles.track_heading}`}>
        <h2>Track</h2>
      </div>

      <div className={`${Styles.track_nav}`}>

        {/* Filter And Search Data */}
        <div className={`${Styles.filter_search_btn}`}>
          <div className={`${Styles.filter_sort}`}>
              <div className={`${Styles.filter}`}>
                <button onClick={()=> {setIsOpen(!isOpen); if(isSortOpen) setIsSortOpen(false);}}><MdFilterListAlt /> Filter</button>
                {isOpen && (
                  <div className={`${Styles.filter_dropdown}`}>
                    <div className={`${Styles.heading}`}>
                      <p>Filter</p>
                      <IoMdClose onClick={()=> setIsOpen(!isOpen)}/>
                    </div>

                    <div className={`${Styles.selectDate}`}>
                      <div className={`${Styles.heading_clear}`}>
                        <p>Select Date</p>
                        <p onClick={handleClear}>Clear</p>
                      </div>
                      <div className={`${Styles.filter_date}`}>
                        <div className={`${Styles.from}`}>
                          <label htmlFor="from">From:</label>
                          <input type="date" id='from' placeholder='00-00-0000' value={filterDate.from} onChange={(e) => setFilterDate({ ...filterDate, from: e.target.value })} />
                        </div>
                        <div className={`${Styles.to}`}>
                          <label htmlFor="to">To:</label>
                          <input type="date" id='to' placeholder='00-00-0000' value={filterDate.to} onChange={(e) => setFilterDate({ ...filterDate, to: e.target.value })} />
                        </div>
                      </div>
                    </div>

                    <div className={`${Styles.selectStatus}`}>
                      <div className={`${Styles.heading_clear}`}>
                        <p>Select Status</p>
                        <p onClick={() => setStatusFilter('')}>Clear</p>
                      </div>
                      <select name="statusFilter" id="statusFilter" value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
                        <option value="">**** Select Status ****</option>
                        <option value="1">Publish</option>
                        <option value="0">Unpublish</option>
                      </select>
                    </div>

                    <div className={`${Styles.buttons}`}>
                      <button onClick={handleReset}>Reset</button>
                      <button onClick={dateStatusApply}>Apply Now</button>
                    </div>
                  </div>
                )}
              </div>

              <div className={`${Styles.sort}`}>
                <button onClick={()=> {setIsSortOpen(!isSortOpen); if(isOpen) setIsOpen(false);}}><BsSortDown /> Sort</button>
                {isSortOpen && (
                  <div className={`${Styles.sort_dropdown}`}>
                    <p onClick={() => {setTrackFilter({...trackFilter, sorting: "a_to_z"}); setIsSortOpen(false);}}><span>A to Z</span><span>{(trackFilter.sorting === "a_to_z") ? <BiCheck /> : ""}</span></p>
                    <p onClick={() => {setTrackFilter({...trackFilter, sorting: "z_to_a"}); setIsSortOpen(false);}}><span>Z to A</span><span>{(trackFilter.sorting === "z_to_a") ? <BiCheck /> : ""}</span></p>
                    <p onClick={() => {setTrackFilter({...trackFilter, sorting: "desc"}); setIsSortOpen(false);}}><span>Latest Date</span><span>{(trackFilter.sorting === "desc") ? <BiCheck /> : ""}</span></p>
                    <p onClick={() => {setTrackFilter({...trackFilter, sorting: "asc"}); setIsSortOpen(false);}}><span>Oldest Date</span><span>{(trackFilter.sorting === "asc") ? <BiCheck /> : ""}</span></p>
                  </div>
                )}
              </div>
          </div>
          <div className={`${Styles.search}`}>
            <input type="text" placeholder="Search" onInput={(e) => setTrackFilter({...trackFilter, search: e.target.value})} />
            <button onClick={() => navigate("/track/create")}> <LuPlusCircle /> Add Track</button>
          </div>
        </div>

        {/* Track Table */}
        <div className={`${Styles.track_songList}`}>
          <div className={`${Styles.track_table}`}>
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Track</th>
                  <th>Status</th>
                  <th>Play</th>
                  <th>Duration</th>
                  <th>Like</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  trackData.length === 0 &&
                    <tr>
                      <td colSpan="7">
                        <div className={`${Styles.dataNotFound}`}>
                        {
                          contentLoader &&
                          <img height="40" width="40" src={ContentLoaderGif} alt="not supported" />
                        }
                        {
                          !contentLoader &&
                          <p>Data not found</p>
                        }
                      </div>
                    </td>
                  </tr>
                }

                {
                  
                  trackData.map((data, index) => {
                    return (
                      <tr>
                        <td>{data.id}</td>
                        <td className={`${Styles.image_name}`}>
                          <div className={`${Styles.image}`}>
                            <img src={data.cover_image} alt="Track Picture1" />
                          </div>
                          <div className={`${Styles.track_name}`}>
                            <p>{data.title}</p>
                          </div>
                        </td>
                        <td>{data.status === 1 ? "Publish" : "Unpublish"}</td>
                        <td className={`${Styles.play}`}>
                          <div className={`${Styles.play_div}`}>
                            <FaUsers />
                            <p>{data.play_count}</p>
                          </div>
                        </td>
                        <td className={`${Styles.time}`}>
                          <div className={`${Styles.time_div}`}>
                            <LuClock7 />
                            <p>{convertDuration(data.track_duration)}</p>
                          </div>
                        </td>
                        <td className={`${Styles.like}`}>
                          <div className={`${Styles.like_div}`}>
                            <FaRegHeart />
                            <p>{data.like_count}</p>
                          </div>
                        </td>
                        <td>
                          <div className={`${Styles.manage}`}>
                            <PiDotsThreeOutlineFill data-trackId={data.id} onClick={handleDropDown} />
                            {
                              dropDownTrackManage.id === data.id && dropDownTrackManage.open === true && (
                                <div className={`${Styles.manageTrack}`}>
                                  <p onClick={() => navigate(`/track/edit/${data.id}`)} ><span><FiEdit /></span> <span>Edit</span></p>
                                  <p onClick={() => {setDeleteTrackDrop(!deleteTrackDrop); setDeleteId(data.id)}}><span><MdDelete /></span> <span>Delete</span></p>
                                </div>
                              )
                            }
                          </div>
                        </td>
                      </tr>
                    )
                  })
                }

              </tbody>
            </table>
          </div>
        </div>

        {/* Pagination */}
        <div>
          <Pagination data={pagination} pageClick={changePage} /> 
        </div>
      </div>
    </div>
  )
}

export default Track
