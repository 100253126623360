// import React from 'react';
import React, { useEffect, useState } from "react";
import { ImCancelCircle } from "react-icons/im";
import { MdDelete } from "react-icons/md"; // Import the delete icon
import Styles from "../../assets/css/addTrack.module.scss";
import axios from "axios";
import { baseUrl } from "../../utils/constant";
import { useDispatch } from "react-redux";
import { popUpBoxTrigger } from "../../features/message/popUpSlice";
import { useNavigate, useParams } from "react-router-dom";
import { IoMdCloseCircle } from "react-icons/io";
import { globalRefreshTrigger } from "../../features/common/refreshSlice";

const AddTrackAlbum = ({ onClose }) => {

    const [songId, setSongId] = useState([]);
    const { albumId } = useParams();
    const [info, setInfo] = useState([]);
    const [popUp, setPopUp] = useState(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();
  
  
    const [search, setSearch] = useState("");
    const [searchData, setSearchData] = useState([]);

    useEffect(() => {
    
        const fetchData = async () => {
    
          if(search != ""){
            try {
              const token = localStorage.getItem("token");
              const userData = JSON.parse(localStorage.getItem("user_data"));
              const deletePlaylist = await axios({
                method: 'GET',
                url: `${baseUrl}artist/album/list-all-music-tracks/${userData.id}/${albumId}/?title=${search}`,
                headers: {
                  'Authorization': 'Bearer ' + token
                }
              });

              const result = deletePlaylist;
              console.log(result);
              if(result.data.status === 200){
                setSearchData(result.data.data.music_track);
              }
            } catch (error) {
              if(error){
                dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "oops!! Something went wrong."}));
              }
            }
          }
        }
        fetchData();
    }, [search]);

    console.log(searchData);

    const handleAddSongToList = (id, name) => {
        const newData = {songId: id, songName: name};
        if(songId.includes(id) === false){
          setInfo([...info, newData]);
          setSongId([...songId, id]);
        }
        setSearch("");
    }

      console.log("song : ", songId);
    
      const handleRemoveSong = (id) => {
        const newIdArray = songId.filter((data) => {
          return data != id; 
        });
    
        const newInfoArray = info.filter((data) => {
          return data.songId != id; 
        });
    
        setSongId(newIdArray);
        setInfo(newInfoArray);
      }
    
      const addTrackToPlaylist = async () => {
        
        try {
          const token = localStorage.getItem("token");
          const userData = JSON.parse(localStorage.getItem("user_data"));
          const deletePlaylist = await axios({
            method: 'POST',
            url: `${baseUrl}artist/album/add-track`,
            headers: {
              'Authorization': 'Bearer ' + token
            },
            data: {
              "tracks_ids": songId,
              "artist_id": userData.id,
              "album_id": albumId
            }
          });
      
          const result = deletePlaylist;
          console.log(result);
          if(result.data.status === 200){
            dispatch(popUpBoxTrigger({type: "success", heading: "Success", message: "Song Add Into Playlist Successfully."}));
            dispatch(globalRefreshTrigger());
            onClose();
          }
        } catch (error) {
          if(error){
            dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "oops!! Something went wrong."}));
          }
        }
      }


  return (
    <div className={Styles.song}>
      <div className={Styles.song_background}>
        <div className={Styles.song_heading}>
          <p>Track</p>
          <ImCancelCircle onClick={onClose} style={{ cursor: "pointer" }} />
        </div>
        <div className={Styles.search_song}>
          <input type="text" value={search} onChange={(e) => setSearch(e.target.value)} placeholder="Search Track" />
          {
            search != "" && 
            <div className={`${Styles.resultSongs}`}>
              <div className={`${Styles.resultHeading}`}>
                <p>Search Result</p>
                <IoMdCloseCircle onClick={() => setSearch("")} />
              </div>
              {
                searchData.length === 0 && 
                <div className={`${Styles.notFound}`}>
                  <p>Data Not Found</p>
                </div>
              }
              {
                searchData.map((data) => {
                  return (
                    songId.includes(data.id) !== true &&
                    <div className={`${Styles.particularSearchSong}`}>
                      <p>{data.title}</p>
                      <button onClick={() => handleAddSongToList(data.id, data.title)}>+</button>
                    </div>
                  )
                })
              }
            </div>
          }
        </div>
        <div className={`${Styles.track_list}`}>
            {
              info.map((data) => {
                return (
                  <div className={`${Styles.dummy_track}`}>
                    <p>{data.songName}</p>
                    <MdDelete onClick={() => handleRemoveSong(data.songId)} />
                  </div>
                )
              })
            }
        </div>
        <div className={Styles.add_btn}>
          <button onClick={addTrackToPlaylist}>Add Track</button>
        </div>
      </div>
    </div>
  )
}

export default AddTrackAlbum
