import React from "react";
import Styles from "../../assets/css/components/artistPlaylist/playlistView.module.scss";

import PlaylistHeading from "./PlaylistHeading";
import PlaylistNavTab from "./PlaylistNavTab";

const PlaylistView = () => {
  return (
    <div className={`${Styles.playlistView}`}>

      <div className={`${Styles.playlistHeading}`}>
        <PlaylistHeading />
      </div>

      <div className={`${Styles.playlistTrack}`}>
        <PlaylistNavTab />
      </div>
    </div>
  )
}

export default PlaylistView
