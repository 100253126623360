import React from 'react';
import Styles from '../../assets/css/error/routeError.module.scss';
import { FaArrowRightLong } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';

const RouteError = () => {
    const navigate = useNavigate();

  return (
    <div className={`${Styles.error}`}>
        <h2>404</h2>
        <p>OOPS! PAGE NOT FOUND</p>
        <button onClick={() => navigate("/dashboard")}>Back to home <FaArrowRightLong /></button>
    </div>
  )
}

export default RouteError
