import React, { useEffect, useState } from "react";
import Styles from "../../assets/css/trackEdit.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { RiArrowLeftSLine } from "react-icons/ri";
import axios from "axios";
import { baseUrl } from "../../utils/constant";
import { popUpBoxTrigger } from "../../features/message/popUpSlice";
import { useDispatch } from "react-redux";
import { AiOutlineLoading3Quarters } from "react-icons/ai";



const TrackEdit = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [category, setCategory] = useState([]);
  const [language, setLanguage] = useState([]);
  const [loading, setLoading]= useState(false);


  const [trackData, setTrackData] = useState({
      id: null,
      title: "",
      description: "",
      release_date: "",
      genre: 1,
      language: 1,
      status: 1
  });

  const [errors, setErrors] = useState({
    artistName: false,
    stageName: false,
    profilePicture: false,
    dob: false,
    country: false,
    PhoneNumber: false,
    gender: false
});


  useEffect(() => {

    const token = localStorage.getItem("token");

    const getCategory = async () => {
  
      try {
        const getAllCategory = await axios.get(`${baseUrl}artist/list-all-category`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });
        const result = getAllCategory;
        console.log(result);
        if(result.data.status === 200){
          console.log("------------------------");
          setCategory(result.data.data.category);
        }
      } catch (error) {
        console.log("Error : ", error);
      }

      try {
        const getAllLanguages = await axios.get(`${baseUrl}artist/language/list`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });
        const result = getAllLanguages;
        console.log(result);
        if(result.data.status === 200){
          setLanguage(result.data.data.languages);
        }
      } catch (error) {
        console.log("Error : ", error);
      }

    }
    getCategory();

    const getTrackData = async () => {

      try {

        const getTrackList = await axios.get(`${baseUrl}artist/music-track/${id}`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });
  
        const result = getTrackList;
        console.log(result);
        if(result.data.status === 200){
          const convertedDate = formatDate(result.data.data.release_date);
          setTrackData({
            id: result.data.data.id,
            title: result.data.data.title,
            description: result.data.data.description,
            release_date: convertedDate,
            genre: result.data.data.category.id,
            language: result.data.data.language.id,
            status: result.data.data.status
          });
        }
      } catch (error) {
        
        console.log("Error : ", error);
      }
    }

    getTrackData();

  }, []);


  const handleUpdateData = async () => {

    setLoading(true);
    const token = localStorage.getItem("token");
    const {id, name, image} = JSON.parse(localStorage.getItem("user_data"));

    const errorData = {
      title: false,
      description: false,
      release_date: false,
      genre: false,
      language: false,
      status: false
    }

    if(trackData.title === "") errorData.title = true;
    if(trackData.description === "") errorData.description = true;
    if(trackData.release_date === "") errorData.release_date = true;
    if(trackData.genre === "") errorData.genre = true;
    if(trackData.language === "") errorData.language = true;
    if(trackData.status === "") errorData.status = true;

    if(trackData.title === "" || trackData.description === "" || trackData.release_date === "" || trackData.genre === "" || trackData.language === "" || trackData.status === ""){
      dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "Please fill all the fields."}));
    }

    setErrors(errorData);

    const formDataSet = new FormData();
    formDataSet.append('id', trackData.id);
    formDataSet.append('artist_id', id);
    formDataSet.append('title', trackData.title);
    formDataSet.append('description', trackData.description);
    formDataSet.append('release_date', trackData.release_date);
    formDataSet.append('category_id', trackData.genre);
    formDataSet.append('language_id', trackData.language);
    formDataSet.append('status', trackData.status);

    try{

      const data = await axios({
        method: 'POST',
        url: `${baseUrl}artist/music-track/update`,
        data: formDataSet,
        headers: {
          'Authorization': 'Bearer ' + token
        }
      });

      const result = data;
      if(result.data.status === 200){

        setLoading(false);
        dispatch(popUpBoxTrigger({type: "success", heading: "Success", message: result.data.message}));
        navigate("/track");

      }else{
        
        dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "oops!! something went wrong"}));
        setLoading(false);

      }
    }catch (error){

      console.log(error);
      
      if(error.response.data.status === 422){
        dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: error.response.data.message}));
        setLoading(false);
        return;
      }

      if(error.response.data.status === 404){
        dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: error.response.data.message}));
        setLoading(false);
        return;
      }

      if(error.response.data.status === 500){
        dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: error.response.data.message}));
        setLoading(false);
        return;
      }
    }

    setLoading(false);

  }

  const formatDate = (timestamp) => {
    const date = new Date(timestamp); // Create a Date object from the timestamp
    const year = date.getFullYear(); // Get the year
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Get the month and pad with zero if needed
    const day = String(date.getDate()).padStart(2, '0'); // Get the day and pad with zero if needed
    return `${year}-${month}-${day}`; // Combine them into 'yyyy-mm-dd' format
  }

  return (
    <div className={`${Styles.track}`}>
        <div className={`${Styles.track_heading}`}>
          <h2>Track</h2>
        </div>

        <div className={`${Styles.trackEdit}`}>
  
          <div className={`${Styles.form_heading}`}>
            <h3>Edit Track</h3>
            <button onClick={() => navigate("/track")}>
              <RiArrowLeftSLine /> Go To Track List
            </button>
          </div>
  
          <div className={`${Styles.edit_form}`}>
            <div className={`${Styles.form_grid}`}>
  
              <div className={`${Styles.form_field}`}>
                <label>Track Title</label>
                <input type="text" value={trackData.title} placeholder="Enter Track Title" onChange={(e)  => setTrackData({...trackData, title: e.target.value })} />
              </div>
              <div className={`${Styles.form_field}`}>
                <label>Track Description</label>
                <textarea type="text" value={trackData.description} placeholder="Enter Track Description" name="description" onChange={(e)  => setTrackData({...trackData, description: e.target.value })}/>
              </div>
            </div>
  
  
            <div className={`${Styles.form_grid}`}>
              <div className={`${Styles.form_field}`}>
                <label>Release Date</label>
                <input type="date" value={trackData.release_date} name="release_date" onChange={(e)  => setTrackData({...trackData, release_date: e.target.value })}/>
              </div>
              <div className={`${Styles.form_field}`}>
                <label>Genre</label>
                <select name="genre" value={trackData.genre} onChange={(e)  => setTrackData({...trackData, genre: e.target.value })}>
                  <option value="">***** Select Genre *****</option>
                  {
                    category.map((data, index) => {
                      return (
                        <option value={data.id}>{data.name}</option>
                      )
                    })
                  }
                </select>
              </div>
            </div>
  
  
            <div className={`${Styles.form_grid}`}>
              <div className={`${Styles.form_field}`}>
                <label>Language</label>
                <select name="language" value={trackData.language} onChange={(e)  => setTrackData({...trackData, language: e.target.value })}>
                  <option value="*">***** Select Language *****</option>
                  {
                    language.map((data) => {
                      return (
                        <option value={data.id}>{data.name}</option>
                      )
                    })
                  }
                </select>
              </div>
              <div className={`${Styles.form_field}`}>
                <label>Status</label>
                <select name="status" value={trackData.status} onChange={(e)  => setTrackData({...trackData, status: e.target.value })}>
                  <option value="*">***** Select Status *****</option>
                  <option value="1">Publish</option>
                  <option value="0">Unpublish</option>
                </select>
              </div>
            </div>
  
  
            {/* <div className={`${Styles.form_grid}`}>
              <div className={`${Styles.form_field}`}>
                <label>Track File</label>
                <div className={`${Styles.customFileInput}`}>
                  <input type="text" placeholder="Choose file..." readOnly />
                  <input type="file" accept=".png,.jpg" id="file-input" name="ImageStyle" />
                </div>
              </div>
              <div className={`${Styles.form_field}`}>
                <label>Cover Picture</label>
                <div className={`${Styles.customFileInput}`}>
                  <input type="text" placeholder="Choose file..." readOnly />
                  <input type="file" accept=".png,.jpg" id="file-input" name="ImageStyle" />
                </div>
              </div>
            </div> */}
  
  
            <div className={`${Styles.track_btn}`}>
              <button className={`${Styles.update}`} disabled={loading ? "disabled" : ""} onClick={handleUpdateData}>{loading ? <AiOutlineLoading3Quarters /> : "Update"}</button>
              <button className={`${Styles.cancel}`} onClick={() => navigate("/track")}>Cancel</button>
            </div>
          </div>
      </div>
    </div>
  )
}

export default TrackEdit
