import React, { useEffect, useState } from 'react';
import Styles from '../../assets/css/components/artistAlbum/albumList.module.scss';
import AlbumCard from "../../components/album/AlbumCard";
import { LuPlusCircle } from "react-icons/lu"; 
import { BsSortDown } from "react-icons/bs";
import { MdFilterListAlt } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import { BiCheck } from "react-icons/bi";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { baseUrl } from '../../utils/constant';
import ContentLoaderGif from "../../assets/images/contentLoader.gif";

const AlbumList = () => {
  
  const [isOpen, setIsOpen] = useState(false);
  const [isSortOpen, setIsSortOpen] = useState(false);
  const [albumData, setAlbumData] = useState([]);
  const navigate = useNavigate();
  const [statusFilter, setStatusFilter] = useState('');
  const [contentLoader, setContentLoader] = useState('true');

    const [filterDate, setFilterDate] = useState({
      from: '',
      to: ''
    });
    const [albumListFilter, setAlbumListFilter] = useState({
      from: '',
      to: '',
      search: '',
      sorting: 'desc'
    });


    const dateStatusApply = () => {
      setAlbumListFilter({...albumListFilter, from: filterDate.from, to: filterDate.to, status: statusFilter});
      setIsOpen(false);
    }

    const handleClear = () => {
      setFilterDate({ from: '', to: '' });
      setStatusFilter('');
    };

    const handleReset = () => {
      setFilterDate({ from: '', to: '' });
      setStatusFilter('');
      setAlbumListFilter({...albumListFilter, from: "", to: "", status: ""});
      setIsOpen(false);
    };

    useEffect(() => {

      const token = localStorage.getItem("token");
      const userData = JSON.parse(localStorage.getItem("user_data"));

      const getAlbumData = async () => {
        try {
          const getPlaylist = await axios.get(`${baseUrl}artist/album/list/${userData.id}?from=${filterDate.from}&to=${filterDate.to}&status=${statusFilter}&search=${albumListFilter.search}&sort=${albumListFilter.sorting}`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          });
    
          const result = getPlaylist;
          console.log(result);
          console.log(result.data.data.album);
          if(result.data.status === 200){
            setContentLoader(result.data.data.album.length !== 0 ? true : false);
            setAlbumData(result.data.data.album);
          }
        } catch (error) {
          
          console.log("Error : ", error);
        }
      }
      getAlbumData();
    }, [albumListFilter]);
  
  return (

    <div className={`${Styles.albums}`}>

        {/* Filter And Search Data */}
        <div className={`${Styles.filter_search_btn}`}>
          <div className={`${Styles.filter_sort}`}>
              <div className={`${Styles.filter}`}>
                <button onClick={()=> {setIsOpen(!isOpen); if(isSortOpen) setIsSortOpen(false);}}><MdFilterListAlt /> Filter</button>
                {isOpen && (
                <div className={`${Styles.filter_dropdown}`}>
                  <div className={`${Styles.heading}`}>
                    <p>Filter</p>
                    <IoMdClose onClick={()=> setIsOpen(!isOpen)}/>
                  </div>

                  <div className={`${Styles.selectDate}`}>
                    <div className={`${Styles.heading_clear}`}>
                      <p>Select Date</p>
                      <p onClick={handleClear}>Clear</p>
                    </div>
                    <div className={`${Styles.filter_date}`}>
                      <div className={`${Styles.from}`}>
                        <label htmlFor="from">From:</label>
                        <input type="date" id='from' placeholder='00-00-0000' value={filterDate.from} onChange={(e) => setFilterDate({ ...filterDate, from: e.target.value })} />
                      </div>
                      <div className={`${Styles.to}`}>
                        <label htmlFor="to">To:</label>
                        <input type="date" id='to' placeholder='00-00-0000' value={filterDate.to} onChange={(e) => setFilterDate({ ...filterDate, to: e.target.value })} />
                      </div>
                    </div>
                  </div>

                  <div className={`${Styles.selectStatus}`}>
                    <div className={`${Styles.heading_clear}`}>
                      <p>Select Status</p>
                      <p onClick={() => setStatusFilter('')}>Clear</p>
                    </div>
                    <select name="statusFilter" id="statusFilter" value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
                      <option value="">**** Select Status ****</option>
                      <option value="1">Publish</option>
                      <option value="0">Unpublish</option>
                    </select>
                  </div>

                  <div className={`${Styles.buttons}`}>
                    <button onClick={handleReset}>Reset</button>
                    <button onClick={dateStatusApply}>Apply Now</button>
                  </div>
                </div>
                )}
              </div>

              <div className={`${Styles.sort}`}>
                <button onClick={()=> {setIsSortOpen(!isSortOpen); if(isOpen) setIsOpen(false);}}><BsSortDown /> Sort</button>
                {isSortOpen && (
                  <div className={`${Styles.sort_dropdown}`}>
                      <p onClick={() => {setAlbumListFilter({...albumListFilter, sorting: "a_to_z"}); setIsSortOpen(false);}}><span>A to Z</span><span>{(albumListFilter.sorting === "a_to_z") ? <BiCheck /> : ""}</span></p>
                      <p onClick={() => {setAlbumListFilter({...albumListFilter, sorting: "z_to_a"}); setIsSortOpen(false);}}><span>Z to A</span><span>{(albumListFilter.sorting === "z_to_a") ? <BiCheck /> : ""}</span></p>
                      <p onClick={() => {setAlbumListFilter({...albumListFilter, sorting: "desc"}); setIsSortOpen(false);}}><span>Latest Date</span><span>{(albumListFilter.sorting === "desc") ? <BiCheck /> : ""}</span></p>
                      <p onClick={() => {setAlbumListFilter({...albumListFilter, sorting: "asc"}); setIsSortOpen(false);}}><span>Oldest Date</span><span>{(albumListFilter.sorting === "asc") ? <BiCheck /> : ""}</span></p>
                  </div>
                )}
              </div>
          </div>
          <div className={`${Styles.search}`}>
            <input type="text" placeholder="Search" onInput={(e) => setAlbumListFilter({...albumListFilter, search: e.target.value})} />
            <button onClick={() => navigate("/album/create")}> <LuPlusCircle /> Add Album</button>
          </div>
        </div>


        {/* Showing The Card List */}
        <div className={`${Styles.cards}`}>

          {
            albumData.length === 0 && 
            <div className={`${Styles.dataNotFound}`}>
              {
                contentLoader &&
                <img height="40" width="40" src={ContentLoaderGif} alt="not supported" />
              }
              {
                !contentLoader &&
                <p>Data not found</p>
              }
            </div>
          }

          {
            albumData.map((data, index) => {
              return (
                <AlbumCard
                  img={data.profile_image}
                  id={data.id}
                  title={data.title}
                  description={data.description}
                  like={data.like_count}
                  save={data.saved_count}
                  status={data.status === 1 ? "Publish" : "Unpublish"}
                  songs={data.music_count}
                />
              )
            })
          }
        </div>

        {/* Pagination For The Album */}
        <div className={`${Styles.pagination}`}> 
            {/* pagination */}
        </div>
    </div>
    
  )
}

export default AlbumList
