import React, { useEffect, useState } from "react";
import Styles from "../../assets/css/components/artistAlbum/albumAdd.module.scss";
import { useNavigate } from "react-router-dom";
import { RiArrowLeftSLine } from "react-icons/ri";
import { useDispatch } from "react-redux";
import axios from "axios";
import { baseUrl } from "../../utils/constant";
import { popUpBoxTrigger } from "../../features/message/popUpSlice";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const AlbumAdd = () => {

  const navigate = useNavigate();
  const [category, setCategory] = useState([]);
  const [language, setLanguage] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const [formData,setFormData] = useState({
    title: "",
    description: "",
    release_date: "",
    genre: "",
    language: "",
    status: '',
    profileImage: null,
    profileImageName: "",
    coverImage: null,
    coverImageName: ""
  });

  useEffect(() => {
    const token = localStorage.getItem("token");
    const getCategory = async () => {

      
      try {
        const getAllCategory = await axios.get(`${baseUrl}artist/list-all-category`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });
        const result = getAllCategory;
        console.log(result);
        if(result.data.status === 200){
          setCategory(result.data.data.category);
        }
      } catch (error) {
        console.log("Error : ", error);
      }

      try {
        const getAllLanguages = await axios.get(`${baseUrl}artist/language/list`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });
        const result = getAllLanguages;
        console.log(result);
        if(result.data.status === 200){
          setLanguage(result.data.data.languages);
        }
      } catch (error) {
        console.log("Error : ", error);
      }

    }
    getCategory();
  }, []);


  const handleCoverImageChange = (e) => {
    const file = e.target.files[0];
    if (file && (file.type === 'image/png' || file.type === 'image/jpeg')) {
      setFormData({...formData, coverImage: file, coverImageName: file.name});
    } else {
      setFormData({...formData, coverImage: null, coverImageName: ""});
      dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "Please select a valid image file (PNG or JPG)."}));
    }
  };

  const handleProfilePictureChange = (e) => {
    const file = e.target.files[0];
    if (file && (file.type === 'image/png' || file.type === 'image/jpeg')) {
      setFormData({...formData, profileImage: file, profileImageName: file.name});
    } else {
      setFormData({...formData, profileImage: null, profileImageName: ""});
      dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "Please select a valid image file (PNG or JPG)."}));
    }
  };

  const handleSubmit = async (e) => {

    e.preventDefault();
    setLoading(true);

    const userData = JSON.parse(localStorage.getItem("user_data"));
    
    if(formData.title === "" || formData.description === "" || formData.release_date === "" || formData.genre === "" || formData.language === "" || formData.status === ""){
      dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "Please fill all the fields."}));
      setLoading(false);
      return;
    }
    
    if (formData.profileImage === "" || formData.profileImageName === "") {      
      dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "Profile picture is required."}));
      setLoading(false);
      return;
    }

    if (formData.coverImage === "" || formData.coverImageName === "") {
      dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "Cover picture is required."}));
      setLoading(false);
      return;
    }

    const formDataSet = new FormData();
    formDataSet.append('title', formData.title);
    formDataSet.append('description', formData.description);
    formDataSet.append('release_date', formData.release_date);
    formDataSet.append('category_id', formData.genre);
    formDataSet.append('language', formData.language);
    formDataSet.append('status', formData.status);
    formDataSet.append('album_profile_image', formData.profileImage);
    formDataSet.append('album_cover_image', formData.coverImage);
    formDataSet.append('artist_id', userData.id);

    const token = localStorage.getItem("token");
    try{

      const data = await axios({
        method: 'POST',
        url: `${baseUrl}artist/album/create`,
        data: formDataSet,
        headers: {
          'Authorization': 'Bearer ' + token
        }
      });

      const result = data;
      if(result.data.status === 200){
        setLoading(false);
        dispatch(popUpBoxTrigger({type: "success", heading: "Success", message: result.data.message}));
        navigate('/album');
      }else{
        console.log("Album Add Failed");
        dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "oops!! something went wrong"}));
        setLoading(false);
      }
    }catch (error){

      console.log(error);
      if(error.response.data.status === 422){
        dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: error.response.data.message}));
        setLoading(false);
        return;
      }

      if(error !== ""){
        dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "oops!! something went wrong"}));
        setLoading(false);
        return;
      }
    }
  };

  return (
    <div className={`${Styles.albumAdd}`}>

        <div className={`${Styles.form_heading}`}>
          <h3>Create New Album</h3>
          <button onClick={() => navigate("/album/list")}>
            <RiArrowLeftSLine /> Go To Album List
          </button>
        </div>

        <div className={`${Styles.add_form}`}>
          <div className={`${Styles.form_grid}`}>

            <div className={`${Styles.form_field}`}>
              <label>Album Title</label>
              <input type="text" placeholder="Enter Album Title" onChange={(e)=> setFormData({...formData, title: e.target.value})} />
            </div>
            <div className={`${Styles.form_field}`}>
              <label>Album Description</label>
              <textarea type="text" placeholder="Enter Album Description" name="description" onChange={(e)=> setFormData({...formData, description: e.target.value})}/>
            </div>
          </div>


          <div className={`${Styles.form_grid}`}>
            <div className={`${Styles.form_field}`}>
              <label>Release Date</label>
              <input type="date" name="release_date" onChange={(e)=> setFormData({...formData, release_date: e.target.value})} />
            </div>
            <div className={`${Styles.form_field}`}>
              <label>Genre</label>
              <select name="genre" onChange={(e)=> setFormData({...formData, genre: e.target.value})}>
                <option value="">***** Select Genre *****</option>
                {
                  category.map((data) => {
                    return (
                      <option value={data.id}>{data.name}</option>
                    )
                  })
                }
              </select>
            </div>
          </div>


          <div className={`${Styles.form_grid}`}>
            <div className={`${Styles.form_field}`}>
              <label>Language</label>
              <select name="language" onChange={(e)=> setFormData({...formData, language: e.target.value})}>
                <option value="">***** Select Language *****</option>
                {
                  language.map((data) => {
                    return (
                      <option value={data.id}>{data.name}</option>
                    )
                  })
                }
              </select>
            </div>
            <div className={`${Styles.form_field}`}>
              <label>Status</label>
              <select name="status" onChange={(e)=> setFormData({...formData, status: e.target.value})}>
                <option value="">***** Select Status *****</option>
                <option value="1">Publish</option>
                <option value="0">Unpublish</option>
              </select>
            </div>
          </div>


          <div className={`${Styles.form_grid}`}>
            <div className={`${Styles.form_field}`}>
              <label>Profile Picture</label>
              <div className={`${Styles.customFileInput}`}>
                <input type="text" value={formData.profileImageName} placeholder="Choose file..." readOnly />
                <input type="file" accept=".png,.jpg" id="file-input" name="ImageStyle" onChange={handleProfilePictureChange} />
              </div>
            </div>
            <div className={`${Styles.form_field}`}>
              <label>Cover Picture</label>
              <div className={`${Styles.customFileInput}`}>
                <input type="text" value={formData.coverImageName} placeholder="Choose file..." readOnly />
                <input type="file" accept=".png,.jpg" id="file-input" name="ImageStyle" onChange={handleCoverImageChange} />
              </div>
            </div>
          </div>


          <div className={`${Styles.album_btn}`}>
            <button className={`${Styles.create}`} disabled={loading ? "disabled" : ""} onClick={handleSubmit}>{loading ? <AiOutlineLoading3Quarters /> : "Create"}</button>
            <button className={`${Styles.cancel}`} onClick={() => navigate("/album/list")}>Cancel</button>
          </div>
        </div>
    </div>
  );
};

export default AlbumAdd;
