import React from 'react';
import { IoMusicalNotesOutline } from "react-icons/io5";
import Styles from "../../assets/css/components/playlistAlbum.module.scss";
import { useNavigate } from 'react-router-dom';

const PlaylistAlbum = (props) => {
  const navigate = useNavigate();
  return (
    <div className={`${Styles.list}`} onClick={() => {navigate(props.navigation)}}>
        <div className={`${Styles.cover_photo}`}>
          <img src={props.img} alt="not supported" />
        </div>

        <div className={`${Styles.dark_cover}`}>
        </div>

        <div className={`${Styles.details}`}>
            <h3>{props.name}</h3>
            <p>{props.totalTrack} <IoMusicalNotesOutline /></p>
        </div>
    </div>
  )
}

export default PlaylistAlbum
