import React from "react";
import Styles from "../../assets/css/album.module.scss";
import { Outlet } from "react-router-dom";


const Album = () => {

  return (
    <div className={`${Styles.album}`}>

      {/* heading & filter */}
      <div className={`${Styles.album_heading}`}>
        <h2>Album</h2>
      </div>


      {/* Album Headings */}
      <Outlet />
      
    </div>
  );
};

export default Album;
