import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    globalRefresh: 0,
}

export const refreshSlice = createSlice({
    name: "globalRefresh",
    initialState: initialState,
    reducers: {
        globalRefreshTrigger: (state, action) => {
            state.globalRefresh = state.globalRefresh + 1;
        }
    }
});

export const { globalRefreshTrigger } = refreshSlice.actions;

export default refreshSlice.reducer;