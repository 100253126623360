import React, { useEffect, useState } from "react";
import Styles from "../../assets/css/components/artistPlaylist/playlistHeading.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { FaPlayCircle } from "react-icons/fa";
import { IoMdArrowRoundBack } from "react-icons/io";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { IoMdAdd } from "react-icons/io";
import DeleteIllustration from "../../assets/images/deleteIllustrator.png";
import AddTrack from "../../pages/AddTrack";
import axios from "axios";
import { baseUrl } from '../../utils/constant';
import { useDispatch } from "react-redux";
import { popUpBoxTrigger } from "../../features/message/popUpSlice";

const PlaylistHeading = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isOpenManage, setIsOpenManage] = useState(false);
  const [deletePlaylistDrop, setDeletePlaylistDrop] = useState(false);
  const [isAddTrackOpen, setIsAddTrackOpen] = useState(false);
  const [playlistData, setPlaylistData] = useState([]);

  function handleClick() {
    navigate("/playlist/list");
  }

  useEffect(() => {

    const token = localStorage.getItem("token");
    const getPlaylistData = async () => {
      try {
        const getPlaylist = await axios.get(`${baseUrl}artist/artist-playlist/get/${id}`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });
    
        const result = getPlaylist;
        console.log(result);
        if(result.data.status === 200){
          setPlaylistData(result.data.data);
        }
      } catch (error) {
        console.log("Error : ", error);
      }
    }
    getPlaylistData();

  }, []);


  const handleCloseAddTrackDropDown = () => {
    setIsAddTrackOpen(false);
  }





  const deletePlaylist = async () => {
    try {
      const token = localStorage.getItem("token");
      const deletePlaylist = await axios({
        method: 'POST',
        url: `${baseUrl}artist/artist-playlist/delete/${id}`,
        headers: {
          'Authorization': 'Bearer ' + token
        }
      });
  
      const result = deletePlaylist;
      if(result.data.status === 200){
        navigate("/playlist/list");
        dispatch(popUpBoxTrigger({type: "success", heading: "Success", message: "Playlist Delete Successfully."}));
      }
    } catch (error) {
      if(error){
        dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "oops!! Something went wrong."}));
      }
    }
  }


  

  return (
    <div className={`${Styles.playlist_heading}`}>

      {
        deletePlaylistDrop && (
          <div className={`${Styles.deletePlaylist}`}>
            <div className={`${Styles.innerDeletePlaylist}`}>
                <div className={`${Styles.heading}`}>
                    <p>Do you really want to delete this Playlist?</p>
                </div>

                <div className={`${Styles.delete_image}`}>
                    <img src={DeleteIllustration} alt="not supported" />
                </div>

                <div className={`${Styles.buttons}`}>
                    <button onClick={deletePlaylist}>Yes, Delete Playlist</button>
                    <button onClick={() => {setDeletePlaylistDrop(false); setIsOpenManage(false)}}>Skip & Continue</button>
                </div>
            </div>
          </div>
        )
      }

      <div className={`${Styles.image}`}>
        <img src={playlistData.cover_image} alt="not supported" />
      </div>

      <div className={`${Styles.dark_layout}`}></div>


      <div className={`${Styles.playlist_back_manage}`}>

        <div className={`${Styles.back}`}>
          <IoMdArrowRoundBack onClick={handleClick} />
        </div>

        <div className={`${Styles.manage}`}>
          <PiDotsThreeOutlineVerticalFill onClick={() => setIsOpenManage(!isOpenManage)}/>
          { isOpenManage && (
            <div className={`${Styles.manage_dropDown}`}>
              <p onClick={() => navigate(`/playlist/edit/${id}`)} ><span><FiEdit /></span> <span>Edit</span></p>
              <p onClick={() => setDeletePlaylistDrop(!deletePlaylistDrop)}><span><MdDelete /></span> <span>Delete</span></p>            
              <p onClick={() => {setIsAddTrackOpen(true); setIsOpenManage(!isOpenManage);}}><span><IoMdAdd /></span> <span>Add Track</span></p>
            </div>
          )}
        </div>

      </div>

      <div className={`${Styles.playlist_description}`}>
        <div className={`${Styles.artist_info}`}>
          <div className={`${Styles.artist_image}`}>
            <img src={playlistData.profile_image} alt="not supported" />
          </div>
          <div className={`${Styles.playlist_details}`}>
            <h3>{playlistData.title}</h3>
            <p>{playlistData.description}</p>
          </div>
        </div>
        <div className={`${Styles.play}`}>
          <FaPlayCircle />
        </div>
      </div>

      {isAddTrackOpen && (
        <AddTrack onClose1={() => setIsAddTrackOpen(false)} onClose={handleCloseAddTrackDropDown}/>
      )}
      
    </div>
  )
}

export default PlaylistHeading
