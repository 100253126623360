import React, { useEffect, useState } from "react";
import Styles from "../../assets/css/components/artistAlbum/albumEdit.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { RiArrowLeftSLine } from "react-icons/ri";
import { useDispatch } from "react-redux";
import axios from "axios";
import { baseUrl } from "../../utils/constant";
import { popUpBoxTrigger } from "../../features/message/popUpSlice";
import { AiOutlineLoading3Quarters } from "react-icons/ai";


const AlbumEdit = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { albumId } = useParams();
    const [category, setCategory] = useState([]);
    const [language, setLanguage] = useState([]);
    const [loading, setLoading]= useState(false);


    const [albumData, setAlbumData] = useState({
      id: 0,
      title: "",
      description: "",
      release_date: "",
      genre: 1,
      language: 1,
      status: 1,
      album_profile_image: null,
      album_cover_image: null,
      album_profile_image_name: "",
      album_cover_image_name: ""
    });


    useEffect(() => {

      const token = localStorage.getItem("token");
      const getCategoryAndLanguage = async () => {
        try {
          const getAllCategory = await axios.get(`${baseUrl}artist/list-all-category`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          });
          const result = getAllCategory;
          if(result.data.status === 200){
            setCategory(result.data.data.category);
          }
        } catch (error) {
          console.log("Error : ", error);
        }
  
        try {
          const getAllLanguages = await axios.get(`${baseUrl}artist/language/list`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          });
          const result = getAllLanguages;
          if(result.data.status === 200){
            setLanguage(result.data.data.languages);
          }
        } catch (error) {
          console.log("Error : ", error);
        }
      }
      getCategoryAndLanguage();


      const getAlbumData = async () => {
        try {
          const getData = await axios.get(`${baseUrl}artist/album/${albumId}`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          });
          const result = getData;
          if(result.data.status === 200){
            const convertedDate = formatDate(result.data.data.release_date);
  
            setAlbumData({
              id: result.data.data.id,
              title: result.data.data.title,
              description: result.data.data.description,
              release_date: convertedDate,
              genre: result.data.data.category_id,
              language: result.data.data.language,
              status: result.data.data.status
            });
          }
        } catch (error) {
          console.log("Error : ", error);
        }
      }
      getAlbumData();
  
    }, []);



    const handleCoverPictureChange = (e) => {
      const file = e.target.files[0];
      if (file && (file.type === 'image/png' || file.type === 'image/jpeg')) {
        setAlbumData({...albumData, album_cover_image: file, album_cover_image_name: file.name});
      } else {
        setAlbumData({...albumData, album_cover_image: null, album_cover_image_name: ""});
        dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "Please select a valid image file (PNG or JPG)."}));
      }
    };
  
    const handleProfilePictureChange = (e) => {
      const file = e.target.files[0];
      if (file && (file.type === 'image/png' || file.type === 'image/jpeg')) {
        setAlbumData({...albumData, album_profile_image: file, album_profile_image_name: file.name});
      } else {
        setAlbumData({...albumData, album_profile_image: null, album_profile_image_name: ""});
        dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "Please select a valid image file (PNG or JPG)."}));
      }
    };


    const formatDate = (timestamp) => {
      const date = new Date(timestamp); // Create a Date object from the timestamp
      const year = date.getFullYear(); // Get the year
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Get the month and pad with zero if needed
      const day = String(date.getDate()).padStart(2, '0'); // Get the day and pad with zero if needed
      return `${year}-${month}-${day}`; // Combine them into 'yyyy-mm-dd' format
    }

    const handleUpdateData = async () => {

      setLoading(true);
      const token = localStorage.getItem("token");
      const {id, name, image} = JSON.parse(localStorage.getItem("user_data"));

      if(albumData.title === "" || albumData.description === "" || albumData.release_date === "" || albumData.genre === "" || albumData.language === "" || albumData.status === ""){
        dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "Please fill all the fields."}));
        return;
      }

      const formDataSet = new FormData();
      formDataSet.append('id', albumData.id);
      formDataSet.append('artist_id', id);
      formDataSet.append('title', albumData.title);
      formDataSet.append('description', albumData.description);
      formDataSet.append('release_date', albumData.release_date);
      formDataSet.append('category_id', albumData.genre);
      formDataSet.append('language', albumData.language);
      formDataSet.append('status', albumData.status);
      if(albumData.album_profile_image_name !== "") formDataSet.append('album_profile_image', albumData.album_profile_image);
      if(albumData.album_cover_image_name !== "") formDataSet.append('album_cover_image', albumData.album_cover_image);
  
      try{
  
        const data = await axios({
          method: 'POST',
          url: `${baseUrl}artist/album/update`,
          data: formDataSet,
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });
  
        const result = data;
        if(result.data.status === 200){
          setLoading(false);
          dispatch(popUpBoxTrigger({type: "success", heading: "Success", message: result.data.message}));
          navigate("/album");
  
        }else{
          
          dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "oops!! something went wrong"}));
          setLoading(false);
  
        }
      }catch (error){
  
        console.log(error);
        
        if(error.response.data.status === 422){
          dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: error.response.data.message}));
          setLoading(false);
          return;
        }
  
        if(error.response.data.status === 404){
          dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: error.response.data.message}));
          setLoading(false);
          return;
        }
  
        if(error.response.data.status === 500){
          dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: error.response.data.message}));
          setLoading(false);
          return;
        }
      }
  
      setLoading(false);
  
    }


    return (
      <div className={`${Styles.albumEdit}`}>
  
          <div className={`${Styles.form_heading}`}>
            <h3>Edit Album</h3>
            <button onClick={() => navigate(`/album/view/${albumId}`)}>
              <RiArrowLeftSLine /> Go To Album View
            </button>
          </div>
  
          <div className={`${Styles.edit_form}`}>
            <div className={`${Styles.form_grid}`}>
  
              <div className={`${Styles.form_field}`}>
                <label>Album Title</label>
                <input type="text" value={albumData.title} placeholder="Enter Album Title" onChange={(e) => setAlbumData({...albumData, title: e.target.value})} />
              </div>
              <div className={`${Styles.form_field}`}>
                <label>Album Description</label>
                <textarea type="text" value={albumData.description} placeholder="Enter Album Description" onChange={(e) => setAlbumData({...albumData, description: e.target.value})} name="description"/>
              </div>
            </div>
  
  
            <div className={`${Styles.form_grid}`}>
              <div className={`${Styles.form_field}`}>
                <label>Release Date</label>
                <input type="date" name="release_date" onChange={(e) => setAlbumData({...albumData, release_date: e.target.value})} value={albumData.release_date}/>
              </div>
              <div className={`${Styles.form_field}`}>
                <label>Genre</label>
                <select name="genre" onChange={(e) => setAlbumData({...albumData, genre: e.target.value})} value={albumData.genre}>
                  <option value="">***** Select Genre *****</option>
                  {
                    category.map((data, index) => {
                      return (
                        <option key={index} value={data.id}>{data.name}</option>
                      )
                    })
                  }
                </select>
              </div>
            </div>
  
  
            <div className={`${Styles.form_grid}`}>
              <div className={`${Styles.form_field}`}>
                <label>Language</label>
                <select name="language" onChange={(e) => setAlbumData({...albumData, language: e.target.value})} value={albumData.language}>
                  <option value="">***** Select Language *****</option>
                  {
                    language.map((data, index) => {
                      return (
                        <option key={index} value={data.id}>{data.name}</option>
                      )
                    })
                  }
                </select>
              </div>
              <div className={`${Styles.form_field}`}>
                <label>Status</label>
                <select name="status" onChange={(e) => setAlbumData({...albumData, status: e.target.value})} value={albumData.status}>
                  <option value="">***** Select Status *****</option>
                  <option value="1">Publish</option>
                  <option value="0">Unpublish</option>
                </select>
              </div>
            </div>
  
  
            <div className={`${Styles.form_grid}`}>
              <div className={`${Styles.form_field}`}>
                <label>Profile Picture</label>
                <div className={`${Styles.customFileInput}`}>
                  <input type="text" placeholder="Choose file..." readOnly value={albumData.album_profile_image_name} />
                  <input type="file" accept=".png,.jpg" id="file-input" name="ImageStyle" onChange={handleProfilePictureChange} />
                </div>
              </div>
              <div className={`${Styles.form_field}`}>
                <label>Cover Picture</label>
                <div className={`${Styles.customFileInput}`}>
                  <input type="text" placeholder="Choose file..." readOnly value={albumData.album_cover_image_name} />
                  <input type="file" accept=".png,.jpg" id="file-input" name="ImageStyle" onChange={handleCoverPictureChange} />
                </div>
              </div>
            </div>
  
  
            <div className={`${Styles.album_btn}`}>
              <button className={`${Styles.create}`} disabled={loading ? "disabled" : ""} onClick={handleUpdateData}>{loading ? <AiOutlineLoading3Quarters /> : "Update"}</button>
              <button className={`${Styles.cancel}`} onClick={() => navigate(`/album/view/${albumId}`)}>Cancel</button>
            </div>
          </div>
      </div>
    );
}

export default AlbumEdit
