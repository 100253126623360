import React, { useEffect } from 'react';
import Styles from "../../assets/css/error/popUpMessage.module.scss";
import { IoClose } from "react-icons/io5";
import { FaCheckCircle } from "react-icons/fa";
import { useDispatch, useSelector } from 'react-redux';
import { popUpBoxTriggerOff } from '../../features/message/popUpSlice';
import { IoCloseCircleSharp } from "react-icons/io5";

const PopUpMessage = () => {
    const show = useSelector(state => state.popUpToggle.popUpToggle);
    const details = useSelector(state => state.popUpToggle.popUpDetails);
    const dispatch = useDispatch();

    useEffect(()=>{
        if(show == true){
            setTimeout(async () => {
                dispatch(popUpBoxTriggerOff());
            }, 3000);
        }
    },[show]);

    return (
        <div className={`${Styles.popUp} ${ show ? Styles.on : ""} ${details.type === "error" && Styles.error} ${details.type === "success" && Styles.success}`}>
            <div className={`${Styles.check}`}>
                {details.type === "success" && <FaCheckCircle className={`${Styles.success}`} />}
                {details.type === "error" && <IoCloseCircleSharp className={`${Styles.error}`} />}
            </div>
            <div className={`${Styles.message}`}>
                <h3>{details.heading}</h3>
                <p>{details.message}</p>
            </div>
            <div className={`${Styles.close}`}>
                <IoClose onClick={() => dispatch(popUpBoxTriggerOff())} />
            </div>
            <div className={`${Styles.line} ${details.type === "error" && Styles.error} ${details.type === "success" && Styles.success}`}>
            </div>
        </div>
    )
}

export default PopUpMessage
