import React from "react";
import Styles from "../../assets/css/playlist.module.scss";
import { Outlet } from "react-router-dom";

const Playlist = () => {
  return (
    <div className={`${Styles.playlist}`}>

      {/* heading & filter */}
      <div className={`${Styles.playlist_heading}`}>
        <h2>Playlist</h2>
      </div>


      {/* Playlist Headings */}
      <Outlet />
      
    </div>
  )
}

export default Playlist
