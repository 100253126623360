import React from "react";
import Styles from "../../assets/css/components/artistAlbum/albumView.module.scss";

import AlbumHeading from "./AlbumHeading";
import AlbumNavTab from "./AlbumNavTab";

const AlbumView = () => {

  return (
    <div className={`${Styles.albumView}`}>

      <div className={`${Styles.albumHeading}`}>
        <AlbumHeading />
      </div>

      <div className={`${Styles.albumTrack}`}>
        <AlbumNavTab />
      </div>
    </div>
  );
};

export default AlbumView;
