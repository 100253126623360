import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    audioToggle: true,
}

export const audioToggleSlice = createSlice({
    name: "audioToggle",
    initialState: initialState,
    reducers: {
        audioToggleOff: (state, action) => {
            state.audioToggle = false;
        },
        audioToggleOn: (state, action) => {
            state.audioToggle = true;
        }
    }
});

export const { audioToggleOff, audioToggleOn } = audioToggleSlice.actions;

export default audioToggleSlice.reducer;