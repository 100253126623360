import * as React from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import Layout from "../layouts/Layout";
import AuthCheck from "./AuthCheck";

import Login from "../pages/authentication/LogIn";
import SignUp from "../pages/authentication/SignUp";
import ForgotPassword from "../pages/authentication/ForgotPassword";
import Dashboard from "../pages/Dashboard";
import Profile from "../pages/Profile";
import Track from "../pages/track/Track";

import Album from "../pages/album/Album";
import AlbumList from "../pages/album/AlbumList";
import AlbumView from "../components/album/AlbumView";
import AlbumAdd from "../components/album/AlbumAdd";
import AlbumEdit from "../components/album/AlbumEdit";

import Playlist from "../pages/playlist/Playlist";
import PlaylistList from "../pages/playlist/PlaylistList";
import PlaylistView from "../components/playlist/PlaylistView";
import PlaylistAdd from "../components/playlist/PlaylistAdd";
import PlaylistEdit from "../components/playlist/PlaylistEdit";

import TrackAdd from "../pages/track/TrackAdd";
import TrackEdit from "../pages/track/TrackEdit";
import RouteError from "./errors/RouteError";
import ApprovalPending from "../pages/authentication/ApprovalPending";
import DeactivateAccount from "../pages/authentication/DeactivateAccount";

import Support from "../pages/Support";

const router = createBrowserRouter([
    {
        path: "/login",
        element: <Login />
    },
    {
        path: "/signUp",
        element: <SignUp />
    },
    {
        path: "/resetPassword",
        element: <ForgotPassword />
    },
    {
        path: "/approvalPending",
        element: <ApprovalPending />
    },
    {
        path: "/disableAccount",
        element: <DeactivateAccount />
    },
    {
        path: "/",
        element: <AuthCheck component={Layout} />,
        children: [
            {
                path: "",
                element: <Navigate to="dashboard" />
            },
            {
                path: "/dashboard",
                element: <AuthCheck component={Dashboard} />
            },
            {
                path: "/profile",
                element: <AuthCheck component={Profile} />
            },
            {
                path: "track",
                element: <AuthCheck component={Track} />
            },
            {
                path: "track/create",
                element: <AuthCheck component={TrackAdd} />
            },
            {
                path: "track/edit/:id",
                element: <AuthCheck component={TrackEdit} />
            },
            {
                path: "playlist",
                element: <AuthCheck component={Playlist} />,
                children: [
                    {
                        path: "",
                        element: <Navigate to="list" />
                    },
                    {
                        path: "list",
                        element: <AuthCheck component={PlaylistList} />
                    },
                    {
                        path: "view/:id",
                        element: <AuthCheck component={PlaylistView} />
                    },
                    {
                        path: "create",
                        element: <AuthCheck component={PlaylistAdd} />
                    },
                    {
                        path: "edit/:playlistId",
                        element: <AuthCheck component={PlaylistEdit} />
                    },
                ]
            },
            {
                path: "album",
                element: <AuthCheck component={Album} />,
                children: [
                    {
                        path: "",
                        element: <Navigate to="list" />
                    },
                    {
                        path: "list",
                        element: <AuthCheck component={AlbumList} />
                    },
                    {
                        path: "view/:albumId",
                        element: <AuthCheck component={AlbumView} />
                    },
                    {
                        path: "create",
                        element: <AuthCheck component={AlbumAdd} />
                    },
                    {
                        path: "edit/:albumId",
                        element: <AuthCheck component={AlbumEdit} />
                    }
                ]
            },
            {
                path: "support",
                element: <AuthCheck component={Support} />
            }
        ]
    },
    {
        path: "*",
        element: <RouteError />
    }
]);

export default router;