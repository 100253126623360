import React from 'react';
import Styles from '../assets/css/layouts/content.module.scss';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import AudioPlayer from '../components/audio/AudioPlayer';

const Content = () => {

  const toggleValue = useSelector(state => state.toggle.toggle);
  const audioToggleValue = useSelector(state => state.audioToggle.audioToggle);

  return (
    <div className={`${Styles.content} ${toggleValue ? Styles.active : ""}`}>
        <div className={`${Styles.container}`}>
          <Outlet />
        </div>
        

        {/* {
          audioToggleValue && 
          <div className={`${Styles.audioPlayer} ${toggleValue ? Styles.active : ""}`}>
            <AudioPlayer />
          </div>
        } */}
    </div>
  )
}

export default Content
