import React, { useState } from 'react';
import Styles from "../../assets/css/auth/artist/resetPassword.module.scss";
import Logo from "../../assets/images/logo/Logo.png";
import { baseUrl } from '../../utils/constant';
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import axios from "axios";
import { useDispatch } from 'react-redux';
import { popUpBoxTrigger } from '../../features/message/popUpSlice';
import PopUpMessage from '../../utils/errors/PopUpMessage';
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { Link, useNavigate } from 'react-router-dom';
import { MdOutlineKeyboardBackspace } from "react-icons/md";


const ForgotPassword = () => {


  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const [newPassword, setNewPassword] = useState({
    password: "",
    confirm_password: ""
  });

  const [showPass, setShowPass] = useState({
    password: false,
    confirm_password: false
  });

  const [content, setContent] = useState("Enter your email address to receive a verification code for resetting your password.");

  const [resetEmail, setResetEmail] = useState("");
  const [screenManage, setScreenManage] = useState({
    email: true,
    otp: false,
    newPass: false
  })
  const [otp, setOtp] = useState();
  const [errors, setErrors] = useState({ email: "", otp: "" });

  const handleResetEmail = async () => {
    setLoading(true);
    let email = document.getElementById("email").value;

    try {
      const sendOtpToMail = await axios({
        method: 'post',
        url: `${baseUrl}artist/forgot-password`,
        data: {
          email: email
        }
      });
      const result = sendOtpToMail;

      if (result.status === 200) {
        dispatch(popUpBoxTrigger({ type: "success", heading: "Success", message: result.data.message }));
        setLoading(false);
        setResetEmail(email);
        setScreenManage({ ...screenManage, otp: true, email: false });
        setContent("Enter the OTP sent to your email address to verify and reset your password.");
      }

      if (result.status != 200) {
        dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "oops!! Something went wrong" }));
      }
    } catch (error) {

      setLoading(false);
      if (error.response.data.status === 404) {
        dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: error.response.data.message }));
        return;
      }

      if ("email" in error.response.data.errors) {
        dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: error.response.data.errors.email[0] }));
      }
    }
  }

  const handleUpdatePassword = async () => {

    setLoading(true);

    if (newPassword.password == "") {
      dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "Password is required." }));
      setLoading(false);
      return;
    }

    if (newPassword.confirmPassword == "") {
      dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "Confirm password is required." }));
      setLoading(false);
      return;
    }

    if (newPassword.password !== newPassword.confirmPassword) {
      dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "Confirm password doesn't match." }))
      setLoading(false);
      return;
    }

    try {

      const update = await axios({
        method: 'post',
        url: `${baseUrl}artist/reset-password`,
        data: {
          email: resetEmail,
          password: newPassword.password,
          confirm_password: newPassword.confirmPassword
        }
      });

      const result = update;
      if (result.data.status === 200) {
        dispatch(popUpBoxTrigger({ type: "success", heading: "Success", message: result.data.message }));
        setScreenManage({ ...screenManage, otp: false, newPass: true });
        setLoading(false);
        navigate("/login");
      }

      if (result.data.status !== 200) {
        dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "oops!! Something went wrong." }));
      }
      setLoading(false);

    } catch (error) {

      console.log(error.response.data.errors);
      console.log("inout");

      if ("password" in error.response.data.errors) {
        console.log("in");
        dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: error.response.data.errors.password[0] }));
        setLoading(false);
      }

      if (error.response.data.status === 422) {
        dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "Please enter correct otp." }));
        setLoading(false);
      }
      setLoading(false);
    }
  }

  const handleOtpSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    try {
      const verifyOtp = await axios({
        method: 'post',
        url: `${baseUrl}artist/verify-otp`,
        data: {
          email: resetEmail,
          otp: otp
        }
      });

      const result = verifyOtp;
      if (result.data.status === 200) {
        dispatch(popUpBoxTrigger({ type: "success", heading: "Success", message: result.data.message }));
        setScreenManage({ ...screenManage, otp: false, newPass: true });
        setLoading(false);
        setContent("Enter your new password and confirm it to update your account password.");
      }

      if (result.data.status !== 200) {
        dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "oops!! Something went wrong." }));
        setLoading(false);
      }

    } catch (error) {

      console.log("Verify Otp Error", error);

      if ("otp" in error.response.data.errors) {
        dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "Please enter correct otp." }));
      }

      if (error.response.data.status === 422) {
        dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "Please enter correct otp." }));
      }
      setLoading(false);
    }

  };

  return (
    <div className={`${Styles.resetMain}`}>

      {/* Reset Password background */}
      <div className={`${Styles.resetBg}`}>

        {/* Logo */}
        <div className={`${Styles.logo}`}>
          <img src={Logo} alt="not supported" />
          <h3>Reset Password</h3>
          <p>{content}</p>
        </div>

        {/* Reset Password Form */}
        {
          screenManage.email === true &&
          <div className={`${Styles.resetForm}`}>
            <div className={`${Styles.email} ${Styles.inputField}`}>
              <label>Email</label>
              <input type="text" placeholder='Enter Your Email' id='email' />
            </div>

            <div className={`${Styles.button}`}>
              <button onClick={handleResetEmail}>{loading ? <AiOutlineLoading3Quarters /> : "Send Otp"}</button>
            </div>
          </div>
        }

        {/* Otp Verify Form */}
        {
          screenManage.otp === true &&
          <div className={`${Styles.resetForm} ${Styles.otpVerify}`}>
            <div className={`${Styles.otp} ${Styles.inputField}`}>
              <label>Verify OTP</label>
              <input type="number" id='otp' onChange={(e) => setOtp(e.target.value)} />
            </div>

            <div className={`${Styles.button}`}>
              <button onClick={handleOtpSubmit}>{loading ? <AiOutlineLoading3Quarters /> : "Verify"}</button>
            </div>
          </div>
        }

        {/* Otp Verify Form */}
        {
          screenManage.newPass === true &&
          <div className={`${Styles.resetForm} ${Styles.otpVerify}`}>
            <div className={`${Styles.new_password} ${Styles.inputField}`}>
              <label>New Password</label>
              <div>
                <input type={(showPass.password) ? "text" : "password"} placeholder='Enter Your New Password' id='new_password' onChange={(e) => setNewPassword({ ...newPassword, password: e.target.value })} />
                {showPass.password && <FaRegEye onClick={() => setShowPass({ ...showPass, password: false })} />}
                {!showPass.password && <FaRegEyeSlash onClick={() => setShowPass({ ...showPass, password: true })} />}
              </div>
            </div>

            <div className={`${Styles.new_confirm_password} ${Styles.inputField}`}>
              <label>Confirm Password</label>
              <div>
                <input type={(showPass.confirm_password) ? "text" : "password"} placeholder='Enter Your Confirm Password' id='new_confirm_password' onChange={(e) => setNewPassword({ ...newPassword, confirmPassword: e.target.value })} />
                {showPass.confirm_password && <FaRegEye onClick={() => setShowPass({ ...showPass, confirm_password: false })} />}
                {!showPass.confirm_password && <FaRegEyeSlash onClick={() => setShowPass({ ...showPass, confirm_password: true })} />}
              </div>
            </div>

            <div className={`${Styles.button}`}>
              <button onClick={handleUpdatePassword}>{loading ? <AiOutlineLoading3Quarters /> : "Update Password"}</button>
            </div>
          </div>
        }

        <div className={`${Styles.redirectLogin}`}>
          <Link to="/login"><MdOutlineKeyboardBackspace /> Back to Login</Link>
        </div>

        <PopUpMessage />
      </div>
    </div>
  )
}

export default ForgotPassword
