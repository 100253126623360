import React, { useEffect, useState } from "react";
import Styles from "../../assets/css/trackAdd.module.scss";
import { useNavigate } from "react-router-dom";
import { RiArrowLeftSLine } from "react-icons/ri";
import axios from "axios";
import { baseUrl } from "../../utils/constant";
import { useDispatch } from "react-redux";
import { popUpBoxTrigger } from "../../features/message/popUpSlice";
import { AiOutlineLoading3Quarters } from "react-icons/ai";


const TrackAdd = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [category, setCategory] = useState([]);
    const [language, setLanguage] = useState([]);
    const userData = JSON.parse(localStorage.getItem("user_data"));
    const dispatch=  useDispatch();

    const [formData,setFormData] = useState({
      artist_id: userData.id,
      title: "",
      description: "",
      release_date: "",
      genre: "",
      language: "",
      duration: 0,
      status: 1,
      track_file: null,
      trackFileName: "",
      coverImage: null,
      coverImageName: ""
    });

    const [error, setError] = useState({
      title: false,
      description: false,
      release_date: false,
      genre: false,
      language: false,
      duration: false,
      status: false,
      track_file: false,
      coverImage: false,
    });

    useEffect(() => {
      const token = localStorage.getItem("token");
      const getCategory = async () => {
  
        
        try {
          const getAllCategory = await axios.get(`${baseUrl}artist/list-all-category`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          });
          const result = getAllCategory;
          console.log(result);
          if(result.data.status === 200){
            setCategory(result.data.data.category);
          }
        } catch (error) {
          console.log("Error : ", error);
        }

        try {
          const getAllLanguages = await axios.get(`${baseUrl}artist/language/list`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          });
          const result = getAllLanguages;
          console.log(result);
          if(result.data.status === 200){
            setLanguage(result.data.data.languages);
          }
        } catch (error) {
          console.log("Error : ", error);
        }

      }
      getCategory();
    }, []);


    const handleCoverPictureChange = (e) => {
      const file = e.target.files[0];
      if (file && (file.type === 'image/png' || file.type === 'image/jpeg')) {
        setFormData({...formData, coverImage: file, coverImageName: file.name});
      } else {
        setFormData({...formData, coverImage: null, coverImageName: ""});
        dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "Please select a valid image file (PNG or JPG)."}));
      }
    };

    const handleTrackFile = (e) => {
      const file = e.target.files[0];
      console.log(file);
      console.log("outside");
      if (file && (file.type === 'audio/mpeg')) {
        console.log("inside");
        const audio = new Audio(URL.createObjectURL(file));
        audio.addEventListener("loadedmetadata", () => {
          setFormData({...formData, track_file: file, duration: audio.duration, trackFileName: file.name});
        });
      } else {
        setFormData({ ...formData, track_file: "", duration: 0, trackFileName: "" });
        dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "Please select a valid audio file (mp3)."}));
      }
    };

    const handleSubmit = async (e) => {

      e.preventDefault();
      setLoading(true);
      console.log("SignUp Data : ", formData);
  
      const errorData = {
        title: false,
        description: false,
        release_date: false,
        genre: false,
        language: false,
        duration: false,
        status: false,
        track_file: false,
        coverImage: false
      }
  
      if(formData.title === "") errorData.title = true;
      if(formData.description === "") errorData.description = true;
      if(formData.release_date === "") errorData.release_date = true;
      if(formData.genre === "") errorData.genre = true;
      if(formData.language === "") errorData.language = true;
      if(formData.duration === "") errorData.duration = true;
      if(formData.status === "") errorData.status = true;
  
      if(formData.title === "" || formData.description === "" || formData.release_date === "" || formData.genre === "" || formData.language === "" || formData.duration === "" || formData.status === ""){
        dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "Please fill all the fields."}));
      }
      
      if (formData.track_file === "" || formData.trackFileName === "") {
        errorData.track_file = true;
        dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "Track file is required."}));
      }

      if (formData.coverImage === "" || formData.coverImageName === "") {
        errorData.coverImage = true;
        dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "Cover picture is required."}));
      }

      setError(errorData);

      if(errorData.title || errorData.description || errorData.release_date || errorData.genre || errorData.language || errorData.duration || errorData.status || errorData.track_file || errorData.coverImage){
        console.log("no one");
        setLoading(false);
        return;

      }else{
        const formDataSet = new FormData();
        formDataSet.append('title', formData.title);
        formDataSet.append('description', formData.description);
        formDataSet.append('release_date', formData.release_date);
        formDataSet.append('category_id', formData.genre);
        formDataSet.append('language_id', formData.language);
        formDataSet.append('track_duration', formData.duration);
        formDataSet.append('status', formData.status);
        formDataSet.append('music_track', formData.track_file);
        formDataSet.append('cover_image', formData.coverImage);
        formDataSet.append('artist_id', formData.artist_id);
    
        const token = localStorage.getItem("token");

        try{
    
          const data = await axios({
            method: 'POST',
            url: `${baseUrl}artist/music-track/create`,
            data: formDataSet,
            headers: {
              'Authorization': 'Bearer ' + token
            }
          });
    
          const result = data;
          if(result.data.status === 200){
            setLoading(false);
            dispatch(popUpBoxTrigger({type: "success", heading: "Success", message: result.data.message}));
            navigate('/track');
          }else{
            console.log("Track Add Failed");
            dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "oops!! something went wrong"}));
            setLoading(false);
          }
        }catch (error){
    
          console.log(error);
          if(error.response.data.status === 422){
            dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: error.response.data.message}));
            setLoading(false);
            return;
          }

          if(error !== ""){
            dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "oops!! something went wrong"}));
            setLoading(false);
            return;
          }
        }

      }
    };

    return (
    <div className={`${Styles.track}`}>
        <div className={`${Styles.track_heading}`}>
          <h2>Track</h2>
        </div>

      <div className={`${Styles.trackAdd}`}>
  
          <div className={`${Styles.form_heading}`}>
            <h3>Add New Track</h3>
            <button onClick={() => navigate("/track")}>
              <RiArrowLeftSLine /> Go To Track List
            </button>
          </div>
  
          <div className={`${Styles.add_form}`}>
            <div className={`${Styles.form_grid}`}>
  
              <div className={`${Styles.form_field}`}>
                <label>Track Title</label>
                <input type="text" placeholder="Enter Track Title" onChange={(e) => setFormData({...formData, title:e.target.value})} />
              </div>
              <div className={`${Styles.form_field}`}>
                <label>Track Description</label>
                <textarea type="text" placeholder="Enter Track Description" name="description" onChange={(e) => setFormData({...formData, description:e.target.value})}/>
              </div>
            </div>
  
  
            <div className={`${Styles.form_grid}`}>
              <div className={`${Styles.form_field}`}>
                <label>Release Date</label>
                <input type="date" name="releaseDate" onChange={(e) => setFormData({...formData, release_date:e.target.value})}/>
              </div>
              <div className={`${Styles.form_field}`}>
                <label>Genre</label>
                <select name="genre" onChange={(e) => setFormData({...formData, genre:e.target.value})}>
                  <option value="">***** Select Genre *****</option>
                  {
                    category.map((data) => {
                      return (
                        <option value={data.id}>{data.name}</option>
                      )
                    })
                  }
                </select>
              </div>
            </div>
  
  
            <div className={`${Styles.form_grid}`}>
              <div className={`${Styles.form_field}`}>
                <label>Language</label>
                <select name="language" onChange={(e) => setFormData({...formData, language:e.target.value})} >
                  <option value="">***** Select Language *****</option>
                  {
                    language.map((data) => {
                      return (
                        <option value={data.id}>{data.name}</option>
                      )
                    })
                  }
                </select>
              </div>
              <div className={`${Styles.form_field}`}>
                <label>Status</label>
                <select name="status" onChange={(e) => setFormData({...formData, status:parseInt(e.target.value)})}>
                  <option value="">***** Select Status *****</option>
                  <option value="1" checked>Publish</option>
                  <option value="0">Unpublish</option>
                </select>
              </div>
            </div>
  
            <div className={`${Styles.form_grid}`}>
              <div className={`${Styles.form_field}`}>
                <label>Track File</label>
                <div className={`${Styles.customFileInput}`} >
                  <input type="text" placeholder="Choose file..." value={formData.trackFileName} readOnly />
                  <input type="file" accept=".mp3" id="file-input" name="ImageStyle" onChange={handleTrackFile} />
                </div>
              </div>
              <div className={`${Styles.form_field}`}>
                <label>Cover Picture</label>
                <div className={`${Styles.customFileInput}`}>
                  <input type="text" placeholder="Choose file..." value={formData.coverImageName} readOnly />
                  <input type="file" accept=".png,.jpg" id="file-input" onChange={handleCoverPictureChange} name="ImageStyle" />
                </div>
              </div>
            </div>

            <div className={`${Styles.track_btn}`}>
              <button className={`${Styles.create}`} disabled={loading ? "disabled" : ""} onClick={handleSubmit}>{loading ? <AiOutlineLoading3Quarters /> : "Create"}</button>
              <button className={`${Styles.cancel}`} onClick={() => navigate("/track")}>Cancel</button>
            </div>
          </div>
      </div>
    </div>
    );
}

export default TrackAdd
