import { configureStore, combineReducers } from "@reduxjs/toolkit";
import toggleSlice from "../features/navbar/toggleSlice";
import popUpSlice from "../features/message/popUpSlice";
import paginationSlice from "../features/pagination/paginationSlice";
import audioToggleSlice from "../features/audio/audioToggleSlice";
import refreshSlice from "../features/common/refreshSlice";


const rootReducer = combineReducers({
    toggle: toggleSlice,
    popUpToggle: popUpSlice,
    pagination: paginationSlice,
    audioToggle: audioToggleSlice,
    globalRefresh: refreshSlice
})

const store = configureStore({
    reducer: rootReducer
});

export default store;