import React, { useEffect, useState } from "react";
import Styles from "../assets/css/support.module.scss";
import { LuPlusCircle } from "react-icons/lu";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { popUpBoxTrigger } from "../features/message/popUpSlice";
import { baseUrl } from "../utils/constant";
import axios from "axios";
import ContentLoaderGif from '../assets/images/contentLoader.gif';

const Support = () => {
  const [addIssueModel, setAddIssueModel] = useState(false);

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [artistIssues, setArtistIssues] = useState([]);
  const [refresh, setRefresh] = useState(0);

  const [artistIds, setArtistIds] = useState([]);
  const [issueSearch, setIssueSearch] = useState([]);
  const [contentLoader, setContentLoader] = useState('true');

  /// Method to get support list
  useEffect(() => {
    const getSupportList = async () => {
      const token = localStorage.getItem("token");
      const data = await axios({
        method: "GET",
        url: `${baseUrl}artist/support/list/${userId}?search=${issueSearch}`,
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })
      if (data.status == 200) {
        setContentLoader(data.data.data.user.length !== 0 ? true : false);
        setArtistIssues(data.data.data.user);
      }
    }
    getSupportList();
  }, [issueSearch, refresh]);

  const [addIssueData, setAddIssueData] = useState({
    issue: "title",
    description: "",
    issuer_name: "",
    status: 0,
    user_id: `${artistIds}`,
    user_type: "artist"
  });

  /// Get User ID for local storage
  let userId;
  let artistName;
  const data = JSON.parse(localStorage.getItem("user_data"));
  userId = data.id;
  artistName = data.name;
  /// 

  /// Function to Submit Support
  const handleAddIssueSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (addIssueData.description === "") {
      dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "Please fill description field." }));
      setLoading(false);
      return;
    }
    const token = localStorage.getItem("token");
    console.log(addIssueData.user_id);
    try {

      const data = await axios({
        method: 'POST',
        url: `${baseUrl}user/support/create`,
        data: {
          "issue": addIssueData.issue,
          "issue_description": addIssueData.description,
          "issuer_name": artistName,
          "status": addIssueData.status,
          "user_id": userId,
          "user_type": "artist"
        },
        headers: {
          'Authorization': 'Bearer ' + token
        }
      });

      const result = data;
      if (result.data.status === 200) {
        setLoading(false);
        dispatch(popUpBoxTrigger({ type: "success", heading: "Success", message: result.data.message }));
        setAddIssueModel(false);
        setRefresh(refresh + 1);
        setArtistIds("");
        setAddIssueData({
          issue: "title",
          description: "",
          issuer_name: "",
          status: "0",
          user_id: { artistIds },
          user_type: "Artist"
        });
      } else {
        console.log("Issue Add Failed");
        dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "oops!! something went wrong" }));
        setLoading(false);
      }
    } catch (error) {

      console.log(error);
      if (error.response.data.status === 422) {
        dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: error.response.data.message }));
        setLoading(false);
        return;
      }

      if (error !== "") {
        dispatch(popUpBoxTrigger({ type: "error", heading: "Error", message: "oops!! something went wrong" }));
        setLoading(false);
        return;
      }
    }
  };

  return (
    <div className={`${Styles.support}`}>

      <div className={`${Styles.support_heading}`}>
        <h2>Support</h2>
      </div>

      {/* Support Table */}
      <div className={`${Styles.channel_data_tracks}`}>
        {
          addIssueModel === false &&
          <>
            <div className={`${Styles.search}`}>
              <input type="text" placeholder="Search" value={issueSearch} onChange={(e) => setIssueSearch(e.target.value)} />
              <button onClick={() => setAddIssueModel(true)}> <LuPlusCircle /> Add Issue</button>
            </div>
            <div className={`${Styles.channel_data_table}`}>
              <table>
                <thead>
                  <tr>
                    <th>#</th>
                    <th className={`${Styles.issue_description}`}>Issue</th>
                    <th>Created Date</th>
                    <th>Status</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    artistIssues.length === 0 &&
                    <tr><td colSpan="7">

                      <div className={`${Styles.dataNotFound}`}>
                        {
                          contentLoader && issueSearch &&
                          <img height="40" width="40" src={ContentLoaderGif} alt="not supported" />
                        }
                        {
                          !contentLoader && issueSearch &&
                          <p>Issue not found</p>
                        }
                      </div>
                    </td></tr>
                  }
                  {artistIssues.map((data) => (
                    <tr key={data.id}>
                      <td>{data.id}</td>
                      <td>
                        <div className={`${Styles.issue_description}`}>
                          <p>{data.issue_description}</p>
                        </div>
                      </td>
                      <td>{new Date(data.createdAt).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' }).replace(/\s+/g, '-')}</td>
                      <td>{data.status === 0 ? "Pending" : "Completed"}</td>
                      <td></td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        }
        {
          addIssueModel === true &&
          <>
            <div className={`${Styles.add_normal_track}`}>

              <div className={`${Styles.track_heading}`}>
                <h2>Add Issue</h2>
              </div>

              <div className={`${Styles.trackAdd}`}>
                <div className={`${Styles.add_form}`}>
                  <div className={`${Styles.form_grid}`}>
                    <div className={`${Styles.form_field}`}>
                      <label>Issue Description</label>
                      <textarea name="" id="" placeholder="Enter Issue Description(300 characters)" maxLength={300} value={addIssueData.description} onChange={(e) => { setAddIssueData({ ...addIssueData, description: e.target.value }); }}></textarea>
                    </div>
                  </div>

                  <div className={`${Styles.buttons}`}>
                    <button className={`${Styles.action}`} disabled={loading ? "disabled" : ""} onClick={handleAddIssueSubmit}>{loading ? <AiOutlineLoading3Quarters /> : "Create"}</button>
                    <button className={`${Styles.cancel}`}
                      onClick={() => {
                        setAddIssueModel(false);
                        setLoading(false);
                        setArtistIds("");
                        setAddIssueData({
                          issue: "title",
                          description: "",
                          issuer_name: "",
                          status: 0,
                          user_id: { artistIds },
                          user_type: "artist"
                        })
                      }}>
                      Cancel</button>
                  </div>
                </div>

              </div>
            </div>
          </>
        }
      </div>
    </div>
  )
}

export default Support
