import { MdOutlineQueueMusic, MdOutlineTrackChanges, MdDashboard, MdHelp } from "react-icons/md";
import { FaClipboardList, FaUser} from "react-icons/fa";

const artistSidebar = [
    {
        navigation: "/dashboard",
        icon: <MdDashboard />,
        label: "Dashboard",
    },
    {
        navigation: "/track",
        icon: <MdOutlineTrackChanges />,
        label: "Track Manage",
    },
    {
        navigation: "/playlist",
        icon: <MdOutlineQueueMusic />,
        label: "Playlist Manage",
    },
    {
        navigation: "/album",
        icon: <FaClipboardList />,
        label: "Album Manage",
    },
    {
        navigation: "/profile",
        icon: <FaUser />,
        label: "Profile Manage",
    },
    {
        navigation: "/support",
        icon: <MdHelp />,
        label: "Support",
    }
]

export {artistSidebar};