import React, { useEffect, useState } from "react";
import Styles from "../assets/css/layouts/navbar.module.scss";
import { FaBars, FaBell, FaUserCircle, FaCog, FaSignOutAlt } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import Profile from "../assets/images/Navbar/profile-logo.png";
import { useDispatch, useSelector } from "react-redux";
import { sidebarOff, sidebarOn } from "../features/navbar/toggleSlice";

const Navbar = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const loginArtistInfo = JSON.parse(localStorage.getItem("user_data"));

  const navigate = useNavigate();

  const toggleValue = useSelector(state => state.toggle.toggle);
  const dispatch = useDispatch();

  const handleProfileClick = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const logout = () => {
    localStorage.removeItem("user_data");
    localStorage.removeItem("token");
    navigate('/login');
  }

  return (
    <div className={`${Styles.navbar} ${toggleValue ? Styles.active : ""}`}>
      <div className={`${Styles.nav} ${Styles.container}`}>
        <div className={`${Styles.toggle}`}>
          <FaBars onClick={() => toggleValue ? dispatch(sidebarOn()) : dispatch(sidebarOff())}/>
        </div>

        <div className={`${Styles.menu}`}>
          <div
            className={`${Styles.profile} ${dropdownOpen ? Styles.open : ""}`}
            onClick={handleProfileClick}
            onMouseLeave={() => setDropdownOpen(false)}
          >
            <div className={`${Styles.profile_photo_name}`}>
              <p>{(loginArtistInfo?.name === "" || loginArtistInfo === null) ? "Undefined" : loginArtistInfo.name}</p>
              <div className={`${Styles.user_logo}`}>
                <img src={(loginArtistInfo?.image === "" || loginArtistInfo === null) ? Profile : loginArtistInfo.image} alt="not supported" />
              </div>
            </div>
            <div className={`${Styles.profile_dropdown}`}>
              <Link to="/profile">
                <FaUserCircle /> Profile
              </Link>
              
              <hr />
              <Link onClick={logout}>
                <FaSignOutAlt /> Logout
              </Link>
            </div>
          </div>

          <div className={`${Styles.notification}`}>
            {/* <FaBell /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
