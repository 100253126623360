import React, { useEffect, useState } from 'react';
import Styles from '../../assets/css/components/artistPlaylist/playlistList.module.scss';
import PlaylistCard from "../../components/playlist/PlaylistCard";
import { LuPlusCircle } from "react-icons/lu"; 
import { BsSortDown } from "react-icons/bs";
import { MdFilterListAlt } from "react-icons/md";
import { IoMdClose } from "react-icons/io";
import { BiCheck } from "react-icons/bi";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { baseUrl } from '../../utils/constant';
import ContentLoaderGif from "../../assets/images/contentLoader.gif";

const PlaylistList = () => {

    const [isOpen, setIsOpen] = useState(false);
    const [isSortOpen, setIsSortOpen] = useState(false);
    const navigate = useNavigate();
    const [statusFilter, setStatusFilter] = useState('');
    const [contentLoader, setContentLoader] = useState('true');

    const [filterDate, setFilterDate] = useState({
      from: '',
      to: ''
    });
    const [playlistFilter, setPlaylistFilter] = useState({
      from: '',
      to: '',
      search: '',
      sorting: 'desc'
    });

    const dateStatusApply = () => {
      setPlaylistFilter({...playlistFilter, from: filterDate.from, to: filterDate.to, status: statusFilter});
      setIsOpen(false);
    }

    const handleClear = () => {
      setFilterDate({ from: '', to: '' });
      setStatusFilter('');
    };
    
    const handleReset = () => {
      setFilterDate({ from: '', to: '' });
      setStatusFilter('');
      setPlaylistFilter({...playlistFilter, from: "", to: "", status: ""});
      setIsOpen(false);
    };

    const [playlistData, setPlaylistData] = useState([]);

      useEffect(() => {

        const token = localStorage.getItem("token");
        const userData = JSON.parse(localStorage.getItem("user_data"));

        const getPlaylistData = async () => {
          try {
            const getPlaylist = await axios.get(`${baseUrl}artist/artist-playlist/list/${userData.id}?from=${filterDate.from}&to=${filterDate.to}&status=${statusFilter}&search=${playlistFilter.search}&sort=${playlistFilter.sorting}`, {
              headers: {
                'Authorization': 'Bearer ' + token
              }
            });
      
            const result = getPlaylist;
            console.log(result);
            console.log(result.data.data.artist_playlist);
            if(result.data.status === 200){
              setContentLoader(result.data.data.artist_playlist.length !== 0 ? true : false);
              setPlaylistData(result.data.data.artist_playlist);
            }
          } catch (error) {
            
            console.log("Error : ", error);
          }
        }
        getPlaylistData();
      }, [playlistFilter]);
    
    return (
  
      <div className={`${Styles.playlists}`}>
  
          {/* Filter And Search Data */}
          <div className={`${Styles.filter_search_btn}`}>
            <div className={`${Styles.filter_sort}`}>
                <div className={`${Styles.filter}`}>
                  <button onClick={()=> {setIsOpen(!isOpen); if(isSortOpen) setIsSortOpen(false);}}><MdFilterListAlt /> Filter</button>
                  {isOpen && (
                  <div className={`${Styles.filter_dropdown}`}>
                    <div className={`${Styles.heading}`}>
                      <p>Filter</p>
                      <IoMdClose onClick={()=> setIsOpen(!isOpen)}/>
                    </div>
  
                    <div className={`${Styles.selectDate}`}>
                      <div className={`${Styles.heading_clear}`}>
                        <p>Select Date</p>
                        <p onClick={handleClear}>Clear</p>
                      </div>
                      <div className={`${Styles.filter_date}`}>
                        <div className={`${Styles.from}`}>
                          <label htmlFor="from">From:</label>
                          <input type="date" id='from' placeholder='00-00-0000' value={filterDate.from} onChange={(e) => setFilterDate({ ...filterDate, from: e.target.value })} />
                        </div>
                        <div className={`${Styles.to}`}>
                          <label htmlFor="to">To:</label>
                          <input type="date" id='to' placeholder='00-00-0000' value={filterDate.to} onChange={(e) => setFilterDate({ ...filterDate, to: e.target.value })} />
                        </div>
                      </div>
                    </div>
  
                    <div className={`${Styles.selectStatus}`}>
                      <div className={`${Styles.heading_clear}`}>
                        <p>Select Status</p>
                        <p onClick={() => setStatusFilter('')}>Clear</p>
                      </div>
                      <select name="statusFilter" id="statusFilter" value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
                        <option value="">**** Select Status ****</option>
                        <option value="1">Publish</option>
                        <option value="0">Unpublish</option>
                      </select>
                    </div>
  
                    <div className={`${Styles.buttons}`}>
                      <button onClick={handleReset}>Reset</button>
                      <button onClick={dateStatusApply}>Apply Now</button>
                    </div>
                  </div>
                  )}
                </div>
  
                <div className={`${Styles.sort}`}>
                  <button onClick={()=> {setIsSortOpen(!isSortOpen); if(isOpen) setIsOpen(false);}}><BsSortDown /> Sort</button>
                  {isSortOpen && (
                    <div className={`${Styles.sort_dropdown}`}>
                      <p onClick={() => {setPlaylistFilter({...playlistFilter, sorting: "a_to_z"}); setIsSortOpen(false);}}><span>A to Z</span><span>{(playlistFilter.sorting === "a_to_z") ? <BiCheck /> : ""}</span></p>
                      <p onClick={() => {setPlaylistFilter({...playlistFilter, sorting: "z_to_a"}); setIsSortOpen(false);}}><span>Z to A</span><span>{(playlistFilter.sorting === "z_to_a") ? <BiCheck /> : ""}</span></p>
                      <p onClick={() => {setPlaylistFilter({...playlistFilter, sorting: "desc"}); setIsSortOpen(false);}}><span>Latest Date</span><span>{(playlistFilter.sorting === "desc") ? <BiCheck /> : ""}</span></p>
                      <p onClick={() => {setPlaylistFilter({...playlistFilter, sorting: "asc"}); setIsSortOpen(false);}}><span>Oldest Date</span><span>{(playlistFilter.sorting === "asc") ? <BiCheck /> : ""}</span></p>
                    </div>
                  )}
                </div>
            </div>
            <div className={`${Styles.search}`}>
              <input type="text" placeholder="Search" onInput={(e) => setPlaylistFilter({...playlistFilter, search: e.target.value})} />
              <button onClick={() => navigate("/playlist/create")}> <LuPlusCircle /> Add Playlist</button>
            </div>
          </div>


          {/* Showing The Card List */}
          <div className={`${Styles.cards}`}>
            {
              playlistData.length === 0 && 
              <div className={`${Styles.dataNotFound}`}>
                {
                  contentLoader &&
                  <img height="40" width="40" src={ContentLoaderGif} alt="not supported" />
                }
                {
                  !contentLoader &&
                  <p>Data not found</p>
                }
              </div>
            }
            {
              playlistData.map((data, index) => {
                return (
                  <PlaylistCard
                    img={data.profile_image}
                    id={data.id}
                    title={data.title}
                    description={data.description}
                    like={data.like_count}
                    save={data.saved_count}
                    status={data.status === 1 ? "Publish" : "Unpublish"}
                    songs={data.music_count}
                  />
                )
              })
            }
          </div>
  
          {/* Pagination For The Album */}
          <div className={`${Styles.pagination}`}> 
              {/* pagination */}
          </div>
      </div>
      
    )
}

export default PlaylistList
