import React, { useEffect, useState } from "react";
import Styles from "../../assets/css/components/artistPlaylist/playlistNavTab.module.scss";
import { RiHeart2Fill } from "react-icons/ri";
import { BsFillBookmarkHeartFill } from "react-icons/bs";
import { FaRegHeart } from "react-icons/fa";
import { PiDotsThreeOutlineFill } from "react-icons/pi";
import ProfileLogo from "../../assets/images/Artist/Album/Track-image.png";
import { LuClock7 } from "react-icons/lu";
import { MdDelete } from "react-icons/md";
import { FaRegEye } from "react-icons/fa";
import DeleteIllustration from "../../assets/images/deleteIllustrator.png";
import { ImHeadphones } from "react-icons/im";
import axios from "axios";
import { baseUrl } from "../../utils/constant";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { popUpBoxTrigger } from "../../features/message/popUpSlice";
import { convertDuration } from "../../utils/convertFunctions";
import { FaUsers } from "react-icons/fa";

const PlaylistNavTab = () => {

  const { id } = useParams();
  const [playlistData, setPlaylistData] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(0);
  const [sorting, setSorting] = useState("song_desc");
  const globalRef = useSelector((state) => state.globalRefresh.globalRefresh);
  console.log("globalRef :", globalRef);


  const [dropDownTrackManage, setDropDownTrackManage] = useState({
    id: 0,
    open: false
  });

  const [deletePlaylistDrop, setDeletePlaylistDrop] = useState(false);



  useEffect(() => {

    const token = localStorage.getItem("token");
    const userData = JSON.parse(localStorage.getItem("user_data"));

    const getPlaylistData = async () => {
      try {
        const getPlaylist = await axios.get(`${baseUrl}artist/artist-playlist/get/${id}?filter=${sorting}`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });
    
        const result = getPlaylist;
        console.log(result);
        if(result.data.status === 200){
          setPlaylistData(result.data.data);
        }
      } catch (error) {
        console.log("Error : ", error);
      }
    }
    getPlaylistData();

  }, [refresh, sorting, globalRef]);

  console.log("Playlist new : ",playlistData);





  const handleDropDown = (e) => {
    let value = e.currentTarget.dataset.trackId;
    value = Number(value);
    if(dropDownTrackManage.id === value){
      setDropDownTrackManage({...dropDownTrackManage, open: !(dropDownTrackManage.open)})
    }

    if(dropDownTrackManage.id !== value){
      setDropDownTrackManage({id: value, open: true});
    }
  }



  const removeSongToPlaylist = async (track) => {
    console.log("done");
    try {
      const token = localStorage.getItem("token");
      const userData = JSON.parse(localStorage.getItem("user_data"));
      const deleteSong = await axios({
        method: 'POST',
        url: `${baseUrl}artist/artist-playlist/remove-track`,
        headers: {
          'Authorization': 'Bearer ' + token
        },
        data: {
          "tracks_ids": track,
          "artist_id": userData.id,
          "playlist_id": id
        }
      });
    
      const result = deleteSong;
      if(result.data.status === 200){
        setRefresh(refresh+1);
        setDropDownTrackManage({
          id: 0,
          open: false
        });
        dispatch(popUpBoxTrigger({type: "success", heading: "Success", message: "Track Remove successfully."}));
      }
    } catch (error) {
      if(error){
        dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "oops!! Something went wrong."}));
      }
    }
    
  }

  return (
    <div className={`${Styles.navtab}`}>

      {
        deletePlaylistDrop && (
        <div className={`${Styles.deletePlaylist}`}>
          <div className={`${Styles.innerDeletePlaylist}`}>
              <div className={`${Styles.heading}`}>
                  <p>Do you really want to delete this track?</p>
              </div>

              <div className={`${Styles.delete_image}`}>
                  <img src={DeleteIllustration} alt="not supported" />
              </div>

              <div className={`${Styles.buttons}`}>
                  <button>Yes, Delete Track</button>
                  <button onClick={() => {setDeletePlaylistDrop(false); setDropDownTrackManage({id: 0, open: false});}}>Skip & Continue</button>
              </div>
          </div>
        </div>
        )
      }

      <div className={`${Styles.navbar}`}>
          <div className={`${Styles.filters}`}>
            <p className={`${Styles.nav_item} ${(sorting === "song_desc") ? Styles.active : ""}`} onClick={(e) => setSorting("song_desc")}>Songs</p>
            <p className={`${Styles.nav_item} ${(sorting === "most_popular") ? Styles.active : ""}`} onClick={(e) => setSorting("most_popular")}>Popular</p>
            <p className={`${Styles.nav_item} ${(sorting === "most_like") ? Styles.active : ""}`} onClick={(e) => setSorting("most_like")}>Most like</p>
          </div>
          <div className={`${Styles.count}`}>
            <div className={`${Styles.song}`}>
              <ImHeadphones />
              <p>{playlistData?.music_count}</p>

            </div>
            <div className={`${Styles.save}`}>
              <BsFillBookmarkHeartFill />
              <p>{playlistData?.saved_count}</p>
            </div>
            <div className={`${Styles.like}`}>
              <RiHeart2Fill />
              <p>{playlistData?.like_count}</p>
            </div>
          </div>
      </div>

      <div className={`${Styles.playlist_songList}`}>
        <div className={`${Styles.track_table}`}>
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>Track</th>
                <th>Duration</th>
                <th>Play</th>
                <th>Like</th>
                <th></th>
              </tr>
            </thead>
            <tbody>

              {
                playlistData?.artist_playlist_tracks.length === 0 &&
                  <tr><td colSpan="6"><div className={`${Styles.dataNotFound}`}>Data Not Found</div></td></tr>
              }

              {
                playlistData?.artist_playlist_tracks.map((data) => {
                  console.log(data.cover_image);
                  return (
                    <tr>
                      <td>{data.id}</td>
                      <td className={`${Styles.image_name}`}>
                        <div className={`${Styles.image}`}>
                          <img src={data.tracks.cover_image} alt="Track Picture1" />
                        </div>
                        <div className={`${Styles.track_name}`}>
                          <p>{data.tracks.title}</p>
                        </div>
                      </td>
                      <td className={`${Styles.time}`}>
                        <div className={`${Styles.time_div}`}>
                          <LuClock7 />
                          <p>{convertDuration(data.tracks.track_duration)}</p>
                        </div>
                      </td>
                      <td className={`${Styles.like}`}>
                        <div className={`${Styles.like_div}`}>
                          <FaUsers />
                          <p>{data.like_count}</p>
                        </div>
                      </td>
                      <td className={`${Styles.like}`}>
                        <div className={`${Styles.like_div}`}>
                          <FaRegHeart />
                          <p>{data.like_count}</p>
                        </div>
                      </td>
                      <td>
                        <div className={`${Styles.manage}`}>
                          <PiDotsThreeOutlineFill data-trackId={data.track_id} onClick={handleDropDown} />
                          {
                            dropDownTrackManage.id === data.track_id && dropDownTrackManage.open === true && (
                              <div className={`${Styles.manageTrack}`}>
                                {/* <p><span><FaRegEye /></span> <span>View Track</span></p> */}
                                <p onClick={() => removeSongToPlaylist(data.track_id)}><span><MdDelete /></span> <span>Remove Track</span></p>
                              </div>
                            )
                          }
                        </div>
                      </td>
                    </tr>
                  )
                })
              }

            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default PlaylistNavTab
