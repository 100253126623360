import React from 'react'
import Styles from "../../assets/css/auth/artist/deactiveAccount.module.scss";
import { MdOutlinePendingActions } from "react-icons/md";
import { FaLongArrowAltRight } from "react-icons/fa";
import { GiShieldDisabled } from "react-icons/gi";
import { useNavigate } from 'react-router-dom';

const DeactivateAccount = () => {
    const navigate = useNavigate();
    return (
        <div className={`${Styles.blockAccount}`}>
        <div className={`${Styles.main}`}>
            <div className={`${Styles.pendingLogo}`}>
                <GiShieldDisabled />
            </div>
            <div className={`${Styles.description}`}>
                <h1>Disabled</h1>
                <p>Your account has been disabled due to a violation of our guidelines or policies. If you wish to reactivate your account, please contact the admin for further assistance.</p>
                <button onClick={() => navigate("/login")}>Back to login <FaLongArrowAltRight /></button>
            </div>
        </div>
        </div>
    )
}

export default DeactivateAccount
