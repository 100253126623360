import React, { useEffect, useState } from 'react';
import Styles from '../../assets/css/auth/artist/signup.module.scss';
import Logo from "../../assets/images/logo/Logo.png";
import { Link, useNavigate } from 'react-router-dom';
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { baseUrl } from '../../utils/constant';
import axios from "axios";
import PopUpMessage from '../../utils/errors/PopUpMessage';
import { useDispatch } from 'react-redux';
import { popUpBoxTrigger } from '../../features/message/popUpSlice';
import { AiOutlineLoading3Quarters } from "react-icons/ai";


const SignUp = () => {

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [countries, setCountries] = useState([]);

  const [signup, setSignup] = useState({
    name: "",
    stageName: "",
    email: "",
    password: "",
    confirmPassword: "",
    profilePicture: null,
    profilePictureName: "",
    date: "",
    month: "",
    year: "",
    PhoneNumber: "",
    countries: ""
  });

  const [error, setError] = useState({
    name: false,
    stageName: false,
    email: false,
    password: false,
    confirmPassword: false,
    profilePicture: false,
    date: false,
    month: false,
    year: false,
    PhoneNumber: false,
    countries: false,
  });

  const [showPass, setShowPass] = useState({
    password: false,
    confirm_password: false
  });

  const dispatch = useDispatch();

  useEffect(() => {
    const getData = async () => {
      try {
        const getCountriesData = await axios.get(`${baseUrl}artist/nationality/list`, {
          // headers: {
          //     'Authorization': 'Bearer ' + token
          // }
        });
        console.log("Result : ",getCountriesData);
        if(getCountriesData.status === 200){
          setCountries(getCountriesData.data.data.nationality);
        }
      } catch (error) {
      }
    }
    getData();
  }, []);

  // Rendering Date -- Month -- Year ####
  const renderDateOptions = () => {
    const dateOptions = [
      <option key={0} value={""}>
        Day
      </option>,
    ];

    for (let i = 1; i <= 31; i++) {
      if(i >= 1 && i < 10){
        dateOptions.push(
          <option key={i} value={`0${i}`}>
            {`0${i}`}
          </option>
        );
      }else{
        dateOptions.push(
          <option key={i} value={i}>
            {i}
          </option>
        );
      }
    }

    return dateOptions;
  };

  const renderMonthOptions = () => {
    const monthOptions = [
      <option key={0} value={""}>
        Month
      </option>,
    ];

    for (let i = 1; i <= 12; i++) {

      if(i >= 1 && i < 10){
        monthOptions.push(
          <option key={i} value={`0${i}`}>
            {new Date(2000, i - 1, 1).toLocaleString("default", {
              month: "long",
            })}
          </option>
        );
      }else{
        monthOptions.push(
          <option key={i} value={i}>
            {new Date(2000, i - 1, 1).toLocaleString("default", {
              month: "long",
            })}
          </option>
        );
      }

    }

    return monthOptions;
  };

  const currentYear = new Date().getFullYear();
  const renderYearOptions = () => {
    const yearOptions = [
      <option key={0} value={""}>
        Year
      </option>,
    ];

    for (let i = currentYear; i >= 1900; i--) {
      yearOptions.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }

    return yearOptions;
  };
  // Rendering Date -- Month -- Year ####


  const validateEmail = (email) => {
    const check = /^[A-Za-z_.0-9]{2,}@[A-Za-z]{2,}[.]{1}[A-Za-z.]{2,6}$/;
    return check.test(email);
  };

  // input only number
  const isInputNumber = (evt) => {
    var ch = String.fromCharCode(evt.which);
    if(!(/[0-9]/.test(ch))){
        evt.preventDefault();
    }
  }

  const handleProfilePictureChange = (e) => {
    const file = e.target.files[0];
    if (file && (file.type === 'image/png' || file.type === 'image/jpeg')) {
      setSignup({...signup, profilePicture: file, profilePictureName: file.name});
    } else {
      setSignup({ ...signup, profilePicture: "", profilePictureName: "" });
      dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "Please select a valid image file (PNG or JPG)."}));
    }
  };

  const handleSubmit = async (e) => {

    e.preventDefault();

    const errorData = {
      name: false,
      stageName: false,
      email: false,
      password: false,
      profilePicture: false,
      date: false,
      month: false,
      year: false,
      PhoneNumber: false,
      countries: false
    }

    if(signup.name === "") errorData.name = true;
    if(signup.stageName === "") errorData.stageName = true;
    if(signup.email === "") errorData.email = true;
    if(signup.password === "") errorData.password = true;
    if(signup.confirmPassword === "") errorData.confirmPassword = true;
    if(signup.date === "") errorData.date = true;
    if(signup.month === "") errorData.month = true;
    if(signup.year === "") errorData.year = true;
    if(signup.PhoneNumber === "") errorData.PhoneNumber = true;
    if(signup.countries === "") errorData.countries = true;

    if(signup.name === "" || signup.stageName === "" || signup.email === "" || signup.password === "" || signup.confirmPassword === "" || signup.date === "" || signup.month === "" || signup.year === "" || signup.PhoneNumber === "" || signup.countries === ""){
      dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "Please fill all the fields."}));
      setLoading(false);
      return;
    }

    if(!validateEmail(signup.email)){
      errorData.email = true;
      dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "Please enter valid email address."}));
      setLoading(false);
      return;
    }
    
    if (signup.profilePicture === "" || signup.profilePictureName === "") {
      errorData.profilePicture = true;
      dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "Profile picture is required."}));
      setLoading(false);
      return;
    }


    if (signup.password !== signup.confirmPassword || signup.password === "") {
      errorData.password = true;
      dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "Confirm password doesn't match."}));
      setLoading(false);
      return;
    }

    console.log(`${signup.year}-${signup.month}-${signup.date}`);

    const formData = new FormData();
    formData.append('artist_name', signup.name);
    formData.append('stage_name', signup.stageName);
    formData.append('email', signup.email);
    formData.append('password', signup.password);
    formData.append('confirm_password', signup.confirmPassword);
    formData.append('image', signup.profilePicture);
    formData.append('phone', signup.PhoneNumber);
    formData.append('nationality', signup.countries);
    formData.append('dob', `${signup.year}-${signup.month}-${signup.date}`);

    try{

      const data = await axios({
        method: 'POST',
        url: `${baseUrl}artist/register`,
        data: formData
      });

      const result = data;
      if(result.data.status === 200){

        setLoading(false);
        dispatch(popUpBoxTrigger({type: "success", heading: "Success", message: result.data.message}));
        navigate('/login');
      }else{
        console.log("Signup Failed");
        dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "oops!! something went wrong"}));
        setLoading(false);

      }
    }catch (error){

      console.log(error);
      if(error.response.data.status === 422){
        dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: error.response.data.message}));
        setLoading(false);
        return;
      }

      console.log(error.response.data.errors.password);

      if("password" in error.response.data.errors){
        dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: error.response.data.errors.password[0]}));
        setLoading(false);
        return;
      }
    }
    
  };

  return (
    <div className={`${Styles.signupMain}`}>

      {/* Signup background */}
      <div className={`${Styles.signupBg}`}>

        {/* Logo */}
        <div className={`${Styles.logo}`}>
          <img src={Logo} alt="not supported" />
        </div>

        {/* Signup Form */}
        <div className={`${Styles.signupForm}`}>

          <div className={`${Styles.title}`}>
            <div className={`${Styles.name} ${Styles.inputField}`}>
              <label>Name</label>
              <input type="text" placeholder="Enter Your Name" onChange={(e) => setSignup({...signup, name: e.target.value})} />
            </div>

            <div className={`${Styles.stagename} ${Styles.inputField}`}>
              <label>Stage Name</label>
              <input type="text" placeholder="Enter Your Stage Name" onChange={(e) => setSignup({...signup, stageName: e.target.value})} /> 
            </div>
          </div>


          <div className={`${Styles.email} ${Styles.inputField}`}>
            <label>Email</label>
            <input type="text" placeholder='Enter Your Email'  onChange={(e) => setSignup({...signup, email: e.target.value})} />
          </div>

          <div className={`${Styles.phoneNumber} ${Styles.inputField}`}>
            <label>Phone Number</label>
            <input type="text" placeholder='Enter Your Phone Number' onKeyPress={(evt) => isInputNumber(evt)} onChange={(e) => setSignup({...signup, PhoneNumber: e.target.value})} />
          </div>

          <div className={`${Styles.country} ${Styles.inputField}`}>
            <label>Country</label>
            <select onChange={(e) => setSignup({...signup, countries: e.target.value})}>
              <option value="">**** Select Country ****</option>
              {
                countries.map((data) => {
                  return <option value={data.id}>{data.name}</option>
                })
              }
            </select>
          </div>


          <div className={`${Styles.date} ${Styles.inputField}`}>
            <label htmlFor="dateField1">Date of Birth</label>
            <div className={`${Styles.datepicker}`}>
              <div className={`${Styles.date} ${Styles.inputField}`}>
                <select onChange={(e) => setSignup({...signup, date: e.target.value})}>
                  {renderDateOptions()}
                </select>
              </div>
              <div className={`${Styles.date} ${Styles.inputField}`}>
                <select onChange={(e) => setSignup({...signup, month: e.target.value})}>
                  {renderMonthOptions()}
                </select>
              </div>
              <div className={`${Styles.date} ${Styles.inputField}`}>
                <select onChange={(e) => setSignup({...signup, year: e.target.value})}>
                  {renderYearOptions()}
                </select>
              </div>
            </div>
          </div>


          <div className={`${Styles.inputField}`}>
            <label>Profile Picture</label>
            <div className={`${Styles.customFileInput}`}>
              <input type="text" placeholder="Choose File..." readOnly value={signup.profilePictureName} />
              <input type="file" accept=".png,.jpg" id="file-input" name="ImageStyle" onChange={handleProfilePictureChange} />
            </div>
          </div>


          <div className={`${Styles.password} ${Styles.inputField}`}>
            <label>Password</label>
            <div>
              <input type={(showPass.password) ? "text" : "password"} placeholder='Enter Your Password' onChange={(e) => setSignup({...signup, password: e.target.value})} />
              {showPass.password && <FaRegEye onClick={() => setShowPass({...showPass, password:false})} />}
              {!showPass.password && <FaRegEyeSlash onClick={() => setShowPass({...showPass, password:true})} />}
            </div>
          </div>


          <div className={`${Styles.confirmpassword} ${Styles.inputField}`}>
            <label>Confirm Password</label>
            <div>
              <input type={(showPass.confirm_password) ? "text" : "password"} placeholder='Enter Your Confirm Password' onChange={(e) => setSignup({...signup, confirmPassword: e.target.value})} />
              {showPass.confirm_password && <FaRegEye onClick={() => setShowPass({...showPass, confirm_password:false})} />}
              {!showPass.confirm_password && <FaRegEyeSlash onClick={() => setShowPass({...showPass, confirm_password:true})} />}
            </div>
          </div>


          <div className={`${Styles.button}`}>
            <button onClick={handleSubmit}>{loading ? <AiOutlineLoading3Quarters /> : "Sign Up"}</button>
            <p>You have an Account? <Link to="/login"> Login</Link></p>
          </div>
        </div>

        <PopUpMessage />

      </div>
    </div>
  )
}

export default SignUp;
