import React, {useEffect, useState} from 'react';
import Styles from '../assets/css/profile.module.scss';
import { FaCamera } from 'react-icons/fa';
import axios from "axios";

import Picture from '../assets/images/Artist/Profile/Profile.png';
import { baseUrl } from '../utils/constant';
import { useDispatch } from 'react-redux';
import { popUpBoxTrigger } from '../features/message/popUpSlice';
import { AiOutlineLoading3Quarters } from "react-icons/ai";


const Profile = () => {

    const dispatch = useDispatch();

    const [formData, setFormData] = useState({
        artistName: '',
        stageName: '',
        dob: '',
        country: '',
        gender: '',
        email: '',
        phone: '',
        instagram: '',
        twitter: '',
        facebook: '',
        shortBio: '',
        longBio: '',
        profilePictureName: '',
        profilePicture: '',
    });

    const [errors, setErrors] = useState({
        artistName: false,
        stageName: false,
        profilePicture: false,
        dob: false,
        country: false,
        PhoneNumber: false,
        gender: false
    });

    const [country, setCountry] = useState([]);
    const [loading, setLoading]= useState(false);

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };

    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
  };

    const handleProfilePictureChange = (e) => {
      const file = e.target.files[0];
      if (file && (file.type === 'image/png' || file.type === 'image/jpeg')) {
        console.log("File", file);
        const reader = new FileReader();
        reader.onload = function(e) {
            const previewImage = document.getElementById('profilePic');
            previewImage.src = e.target.result;
        };
        reader.readAsDataURL(file);
        setFormData({...formData, profilePicture: file, profilePictureName: file.name});
      } else {
        setFormData({ ...formData, profilePicture: "", profilePictureName: "" });
        dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "Please select a valid image file (PNG or JPG)."}));
      }
    };

    // input only number
    const isInputNumber = (evt) => {
      var ch = String.fromCharCode(evt.which);
      if(!(/[0-9]/.test(ch))){
          evt.preventDefault();
      }
    }

    const handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);

      const errorData = {
        artistName: false,
        stageName: false,
        profilePicture: false,
        dob: false,
        PhoneNumber: false,
        gender: false
      }

      if(formData.artistName === "") errorData.name = true;
      if(formData.stageName === "") errorData.stageName = true;
      if(formData.dob === "") errorData.dob = true;
      if(formData.PhoneNumber === "") errorData.PhoneNumber = true;
      if(formData.gender === "") errorData.gender = true;

      if(formData.artistName === "" || formData.stageName === "" || formData.date === "" || formData.PhoneNumber === "" || formData.gender === ""){
        dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "Please fill all the fields."}));
      }

      setErrors(errorData);

      const token = localStorage.getItem("token");
      const {id, name, image} = JSON.parse(localStorage.getItem("user_data"));
      console.log("id :",id);
      const formDataSet = new FormData();
      formDataSet.append('user_id', id);
      formDataSet.append('artist_name', formData.artistName);
      formDataSet.append('stage_name', formData.stageName);
      formDataSet.append('gender', formData.gender);
      if(formData.profilePictureName !== "") formDataSet.append('image', formData.profilePicture);

      formDataSet.append('phone', formData.PhoneNumber);
      formDataSet.append('nationality', formData.country);
      formDataSet.append('dob', formData.dob);

      formDataSet.append('instagram_url', formData.instagram);
      formDataSet.append('twitter_url', formData.twitter);
      formDataSet.append('facebook_url', formData.facebook);

      formDataSet.append('short_bio', formData.shortBio);
      formDataSet.append('long_bio', formData.longBio);

      try{

        const data = await axios({
          method: 'POST',
          url: `${baseUrl}artist/update-profile`,
          data: formDataSet,
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });

        const result = data;
        if(result.data.status === 200){

          setLoading(false);
          dispatch(popUpBoxTrigger({type: "success", heading: "Success", message: result.data.message}));

        }else{
          console.log("Signup Failed");
          dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "oops!! something went wrong"}));
          setLoading(false);

        }
      }catch (error){

        console.log(error);
        
        if(error.response.data.status === 422){
          dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: error.response.data.message}));
          setLoading(false);
          return;
        }

        if(error.response.data.status === 404){
          dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: error.response.data.message}));
          setLoading(false);
          return;
        }

        if("gender" in error.response.data.errors){
          dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: error.response.data.errors.gender[0]}));
          setLoading(false);
          return;
        }
      }
      
    };

    useEffect(() => {

      const getToken = localStorage.getItem('token');
      async function getCountryList(){
        try {
          const getAllCategory = await axios.get(`${baseUrl}artist/nationality/list`, {
            headers: {
              'Authorization': 'Bearer ' + getToken
            }
          });
          const result = getAllCategory;
          console.log(result);
          if(result.data.status === 200){
            setCountry(result.data.data.nationality);
          }
        } catch (error) {
          console.log("Error : ", error);
        }
      }
      getCountryList();

      async function getProfileData(){
        const {id, name, image} = JSON.parse(localStorage.getItem("user_data"));

        const getData = await axios.get(`${baseUrl}artist/${id}`, {
          headers: {
            'Authorization': 'Bearer ' + getToken
          }
        });

        const result = getData;
        if(result.status != 200){
          dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "Artist data not found."}));
        }

        if(result.status === 200){
          // console.log(result.data.data);
          const dobFormatted = formatDate(result.data.data.dob);
          const setData = {
            artistName: result.data.data.artist_name,
            stageName: result.data.data.stage_name,
            // dob: "2024-09-25",
            // dob: result.data.data.dob,
            dob: dobFormatted,
            country: result.data.data.nationality,
            gender: result.data.data.gender === null ? "" : result.data.data.gender,
            email: result.data.data.email,
            phone: result.data.data.phone_number,
            instagram: result.data.data.instagram_url === null ? "" : result.data.data.instagram_url,
            twitter: result.data.data.twitter_url === null ? "" : result.data.data.twitter_url,
            facebook: result.data.data.facebook_url === null ? "" : result.data.data.facebook_url,
            shortBio: result.data.data.short_bio === null ? "" : result.data.data.short_bio,
            longBio: result.data.data.long_bio === null ? "" : result.data.data.long_bio,
            profilePicture: result.data.data.image_url === null ? Picture : result.data.data.image_url,
            profilePictureName: ''
          };
          setFormData(setData);
        }
      }
      getProfileData();

    }, []);

    // console.log("FormData: ",formData);

  return (
    <div className={`${Styles.profile}`}>
      <div className={`${Styles.profile_heading}`}>
        <h2>Profile information</h2>
      </div>

      <div className={`${Styles.profile_box}`}>
        <div className={`${Styles.profile_manage}`}>

          <div className={`${Styles.profile_picture_edit}`}>
            <input type="file" id="fileInput" accept="image/*" style={{ display: 'none' }} onChange={handleProfilePictureChange} />
            <img src={formData.profilePicture || Picture} id='profilePic' alt="profile" />

            <div className={`${Styles.camera_icon}`}>
              <FaCamera onClick={() => document.getElementById('fileInput').click()} />
            </div>
          </div>

          <hr />

          <div className={`${Styles.information}`}>
              <p>Information</p>
              <div className={`${Styles.sub_information}`}>
                  <div className={`${Styles.information_field}`}>
                      <label>Artist Name</label>
                      <input type="text" placeholder="Enter Artist Name" name="artistName" value={formData.artistName || "N/A"} onChange={handleInputChange} />
                      {errors.artistName && <span>{errors.artistName}</span>}
                  </div>
                  <div className={`${Styles.information_field}`}>
                      <label>Stage Name</label>
                      <input type="text" placeholder="Enter Stage Name" name="stageName" value={formData.stageName || "N/A"} onChange={handleInputChange}/>
                      {errors.stageName && <span>{errors.stageName}</span>}
                  </div>
              </div>
              <div className={`${Styles.sub_information}`}>
                  <div className={`${Styles.information_field}`}>
                      <label>Date of Birth</label>
                      <input type="date" name="dob" value={formData.dob || "N/A"} onChange={handleInputChange}/>
                      {errors.dob && <span>{errors.dob}</span>}
                  </div>
                  <div className={`${Styles.information_field}`}>
                    <label>Country</label>
                    <select name="country" value={formData.country || "N/A"} onChange={handleInputChange}>
                      <option value="">**** Select Country ****</option>
                      {country.map((data, index) => (
                        <option key={index} value={data.id}>
                          {data.name}
                        </option>
                      ))}
                    </select>
                    {errors.nationality && <span>{errors.nationality}</span>}
                  </div>
              </div>
              <div className={`${Styles.sub_information}`}>
                  <div className={`${Styles.information_field}`}>
                      <label>Gender</label>
                      <select name="gender" value={formData.gender || "N/A"} onChange={handleInputChange}>
                          <option value="">Select Gender</option>
                          <option value="men">Male</option>
                          <option value="women">Female</option>
                          <option value="other">Other</option>
                      </select>
                      {errors.gender && <span>{errors.gender}</span>}
                  </div>
              </div>
              <p>Contact Information</p>
              <div className={`${Styles.sub_information}`}>
                  <div className={`${Styles.information_field}`}>
                      <label>Email</label>
                      <input type="text" placeholder="Enter Your Email" name="email" value={formData.email || "N/A"} onChange={handleInputChange} disabled/>
                      {errors.email && <span>{errors.email}</span>}
                  </div>
                  <div className={`${Styles.information_field}`}>
                      <label>Phone Number</label>
                      <input type="text" placeholder="Enter Your Phone Number" onKeyPress={(evt) => isInputNumber(evt)} name="phone" value={formData.phone || "N/A"} onChange={handleInputChange}/>
                      {errors.phone && <span>{errors.phone}</span>}
                  </div>
              </div>
              <p>Social Media Handles</p>
              <div className={`${Styles.sub_information}`}>
                  <div className={`${Styles.information_field}`}>
                      <label>Instagram</label>
                      <input type="text" placeholder="Enter Your Instagram Link"  name="instagram" value={formData.instagram || "N/A"} onChange={handleInputChange}/>
                      {errors.instagram && <span>{errors.instagram}</span>}
                      {/* {errors.instagram_url && <span>{errors.instagram_url}</span>} */}
                  </div>
                  <div className={`${Styles.information_field}`}>
                      <label>Twitter</label>
                      <input type="text" placeholder="Enter Your Twitter Link" name="twitter" value={formData.twitter || "N/A"} onChange={handleInputChange}/>
                      {errors.twitter && <span>{errors.twitter}</span>}
                  </div>
              </div>
              <div className={`${Styles.sub_information}`}>
                  <div className={`${Styles.information_field}`}>
                      <label>Facebook</label>
                      <input type="text" placeholder="Enter Your Facebook Link" name="facebook" value={formData.facebook || "N/A"} onChange={handleInputChange}/>
                      {errors.facebook && <span>{errors.facebook}</span>}
                  </div>
              </div>
              <p>Biography</p>
              <div className={`${Styles.sub_information}`}>
                  <div className={`${Styles.information_field}`}>
                      <label>Short Bio</label>
                      <textarea type="text" placeholder="Enter Your Short Bio" name="shortBio" value={formData.shortBio} onChange={handleInputChange}/>
                      {errors.shortBio && <span>{errors.shortBio}</span>}
                  </div>
                  <div className={`${Styles.information_field}`}>
                      <label>Long Bio</label> 
                      <textarea type="text" placeholder="Enter Your Long Bio" name="longBio" value={formData.longBio} onChange={handleInputChange}/>
                      {errors.longBio && <span>{errors.longBio}</span>}
                  </div>
              </div>
          </div>
          <div className={`${Styles.profile_btn}`}>
              <button onClick={handleSubmit} disabled={loading ? "disabled" : ""}>{loading ? <AiOutlineLoading3Quarters /> : "Update"}</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Profile
