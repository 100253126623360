import React, { useEffect, useState } from "react";
import Styles from "../../assets/css/components/artistPlaylist/playlistEdit.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { RiArrowLeftSLine } from "react-icons/ri";
import { useDispatch } from "react-redux";
import axios from "axios";
import { baseUrl } from "../../utils/constant";
import { popUpBoxTrigger } from "../../features/message/popUpSlice";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const PlaylistEdit = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { playlistId } = useParams();
  const [category, setCategory] = useState([]);
  const [language, setLanguage] = useState([]);
  const [loading, setLoading]= useState(false);

  const [playlistData, setPlaylistData] = useState({
    id: 0,
    title: "",
    description: "",
    release_date: "",
    genre: 1, 
    language: 1,
    status: 1,
    playlist_profile_image: null,
    playlist_cover_image: null,
    playlist_profile_image_name: "",
    playlist_cover_image_name: ""
  });


  useEffect(() => {

    const token = localStorage.getItem("token");

    const getCategoryAndLanguage = async () => {
      try {
        const getAllCategory = await axios.get(`${baseUrl}artist/list-all-category`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });
        const result = getAllCategory;
        if(result.data.status === 200){
          setCategory(result.data.data.category);
        }
      } catch (error) {
        console.log("Error : ", error);
      }

      try {
        const getAllLanguages = await axios.get(`${baseUrl}artist/language/list`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });
        const result = getAllLanguages;
        if(result.data.status === 200){
          setLanguage(result.data.data.languages);
        }
      } catch (error) {
        console.log("Error : ", error);
      }
    }
    getCategoryAndLanguage();



    const getPlaylistData = async () => {

      try {
        const getData = await axios.get(`${baseUrl}artist/artist-playlist/get/${playlistId}`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });
        const result = getData;
        if(result.data.status === 200){
          const convertedDate = formatDate(result.data.data.release_date);

          setPlaylistData({
            id: result.data.data.id,
            title: result.data.data.title,
            description: result.data.data.description,
            release_date: convertedDate,
            genre: result.data.data.category_id,
            language: result.data.data.language_id,
            status: result.data.data.status
          });
        }
      } catch (error) {
        console.log("Error : ", error);
      }
    }
    getPlaylistData();

  }, []);


  const formatDate = (timestamp) => {
    const date = new Date(timestamp); // Create a Date object from the timestamp
    const year = date.getFullYear(); // Get the year
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Get the month and pad with zero if needed
    const day = String(date.getDate()).padStart(2, '0'); // Get the day and pad with zero if needed
    return `${year}-${month}-${day}`; // Combine them into 'yyyy-mm-dd' format
  }


  const handleCoverPictureChange = (e) => {
    const file = e.target.files[0];
    if (file && (file.type === 'image/png' || file.type === 'image/jpeg')) {
      setPlaylistData({...playlistData, playlist_cover_image: file, playlist_cover_image_name: file.name});
    } else {
      setPlaylistData({...playlistData, playlist_cover_image: null, playlist_cover_image_name: ""});
      dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "Please select a valid image file (PNG or JPG)."}));
    }
  };

  const handleProfilePictureChange = (e) => {
    const file = e.target.files[0];
    if (file && (file.type === 'image/png' || file.type === 'image/jpeg')) {
      setPlaylistData({...playlistData, playlist_profile_image: file, playlist_profile_image_name: file.name});
    } else {
      setPlaylistData({...playlistData, playlist_profile_image: null, playlist_profile_image_name: ""});
      dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "Please select a valid image file (PNG or JPG)."}));
    }
  };


  const handleUpdateData = async () => {

    setLoading(true);
    const token = localStorage.getItem("token");
    const {id, name, image} = JSON.parse(localStorage.getItem("user_data"));

    if(playlistData.title === "" || playlistData.description === "" || playlistData.release_date === "" || playlistData.genre === "" || playlistData.language === "" || playlistData.status === ""){
      dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "Please fill all the fields."}));
      return;
    }

    console.log("Playlist Id : ", playlistData.id);
    console.log("Artist Id : ", id);

    const formDataSet = new FormData();
    formDataSet.append('id', playlistData.id);
    formDataSet.append('artist_id', id);
    formDataSet.append('title', playlistData.title);
    formDataSet.append('description', playlistData.description);
    formDataSet.append('release_date', playlistData.release_date);
    formDataSet.append('category_id', playlistData.genre);
    formDataSet.append('language_id', playlistData.language);
    formDataSet.append('status', playlistData.status);
    if(playlistData.playlist_profile_image_name !== "") formDataSet.append('playlist_profile_image', playlistData.playlist_profile_image);
    if(playlistData.playlist_cover_image_name !== "") formDataSet.append('playlist_cover_image', playlistData.playlist_cover_image);

    try{

      const data = await axios({
        method: 'POST',
        url: `${baseUrl}artist/artist-playlist/update`,
        data: formDataSet,
        headers: {
          'Authorization': 'Bearer ' + token
        }
      });

      const result = data;
      if(result.data.status === 200){
        setLoading(false);
        dispatch(popUpBoxTrigger({type: "success", heading: "Success", message: result.data.message}));
        navigate("/playlist");

      }else{
        
        dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "oops!! something went wrong"}));
        setLoading(false);

      }
    }catch (error){

      console.log(error);
      
      if(error.response.data.status === 422){
        dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: error.response.data.message}));
        setLoading(false);
        return;
      }

      if(error.response.data.status === 404){
        dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: error.response.data.message}));
        setLoading(false);
        return;
      }

      if(error.response.data.status === 500){
        dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: error.response.data.message}));
        setLoading(false);
        return;
      }
    }

    setLoading(false);

  }

    return (
      <div className={`${Styles.playlistEdit}`}>
  
          <div className={`${Styles.form_heading}`}>
            <h3>Edit Playlist</h3>
            <button onClick={() => navigate(`/playlist/view/${playlistId}`)}>
              <RiArrowLeftSLine /> Go To Playlist View
            </button>
          </div>
  
          <div className={`${Styles.edit_form}`}>
            <div className={`${Styles.form_grid}`}>
  
              <div className={`${Styles.form_field}`}>
                <label>Playlist Title</label>
                <input type="text" placeholder="Enter Playlist Title" value={playlistData.title} onChange={(e) => setPlaylistData({...playlistData, title: e.target.value })} />
              </div>
              <div className={`${Styles.form_field}`}>
                <label>Playlist Description</label>
                <textarea type="text" placeholder="Enter Playlist Description" value={playlistData.description} onChange={(e) => setPlaylistData({...playlistData, description: e.target.value })} />
              </div>
            </div>
  
  
            <div className={`${Styles.form_grid}`}>
              <div className={`${Styles.form_field}`}>
                <label>Release Date</label>
                <input type="date" name="release_date" value={playlistData.release_date} onChange={(e)  => setPlaylistData({...playlistData, release_date: e.target.value })} />
              </div>
              <div className={`${Styles.form_field}`}>
                <label>Genre</label>
                <select name="genre" value={playlistData.genre} onChange={(e)  => setPlaylistData({...playlistData, genre: e.target.value })} >
                  <option value="">***** Select Genre *****</option>
                  {
                    category.map((data, index) => {
                      return (
                        <option value={data.id}>{data.name}</option>
                      )
                    })
                  }
                </select>
              </div>
            </div>
  
  
            <div className={`${Styles.form_grid}`}>
              <div className={`${Styles.form_field}`}>
                <label>Language</label>
                <select name="language" value={playlistData.language} onChange={(e)  => setPlaylistData({...playlistData, language: e.target.value })} >
                  <option value="*">***** Select Language *****</option>
                  {
                    language.map((data) => {
                      return (
                        <option value={data.id}>{data.name}</option>
                      )
                    })
                  }
                </select>
              </div>
              <div className={`${Styles.form_field}`}>
                <label>Status</label>
                <select name="status" value={playlistData.status} onChange={(e)  => setPlaylistData({...playlistData, status: e.target.value })}>
                  <option value="*">***** Select Status *****</option>
                  <option value="1">Publish</option>
                  <option value="0">Unpublish</option>
                </select>
              </div>
            </div>
  
  
            <div className={`${Styles.form_grid}`}>
              <div className={`${Styles.form_field}`}>
                <label>Profile Picture</label>
                <div className={`${Styles.customFileInput}`}>
                  <input type="text" value={playlistData.playlist_profile_image_name} placeholder="Choose file..." readOnly />
                  <input type="file" accept=".png,.jpg" id="file-input" name="ImageStyle" onChange={handleProfilePictureChange} />
                </div>
              </div>
              <div className={`${Styles.form_field}`}>
                <label>Cover Picture</label>
                <div className={`${Styles.customFileInput}`}>
                  <input type="text" value={playlistData.playlist_cover_image_name} placeholder="Choose file..." readOnly />
                  <input type="file" accept=".png,.jpg" id="file-input" name="ImageStyle" onChange={handleCoverPictureChange} />
                </div>
              </div>
            </div>
  

            <div className={`${Styles.playlist_btn}`}>
              <button className={`${Styles.create}`} disabled={loading ? "disabled" : ""} onClick={handleUpdateData}>{loading ? <AiOutlineLoading3Quarters /> : "Update"}</button>
              <button className={`${Styles.cancel}`} onClick={() => navigate(`/playlist/view/${playlistId}`)}>Cancel</button>
            </div>
          </div>
      </div>
    );
}

export default PlaylistEdit
