import React from "react";
import Styles from "../../assets/css/components/artistAlbum/albumCard.module.scss";
import { IoMdLock } from "react-icons/io";
import { ImHeadphones } from "react-icons/im";
import { BsFillBookmarkHeartFill } from "react-icons/bs";
import { RiHeart2Fill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";


const AlbumCard = (props) => {

  const navigate = useNavigate();

  function handleClick() {
    navigate(`/album/view/${props.id}`);
  }
  
  return (
    <div className={`${Styles.album}`} onClick={handleClick}>

      <div className={`${Styles.image}`}>
        <img src={props.img} alt="not supported" />
      </div>

      <div className={`${Styles.dark_layout}`}>
      </div>

      <div className={`${Styles.title_description}`}>
          <h3>{props.title}</h3>
          <p>{props.description}</p>
      </div>

      <div className={`${Styles.sub_details}`}>
        <div className={`${Styles.album_type}`}>
          <IoMdLock />
          <p>{props.status}</p>
        </div>

        <div className={`${Styles.info}`}>
          <div className={`${Styles.songs_count}`}>
              <ImHeadphones />
              <p>{props.songs}</p>
          </div>

          <div className={`${Styles.save_count}`}>
              <BsFillBookmarkHeartFill />
              <p>{props.save}</p>
          </div>

          <div className={`${Styles.like_count}`}>
              <RiHeart2Fill />
              <p>{props.like}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AlbumCard;
