import React, { useEffect, useState } from "react";
import Styles from "../../assets/css/components/artistAlbum/albumHeading.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { FaPlayCircle } from "react-icons/fa";
import { IoMdArrowRoundBack } from "react-icons/io";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { IoMdAdd } from "react-icons/io";
import DeleteIllustration from "../../assets/images/deleteIllustrator.png";
import AddTrack from "../../pages/AddTrack";
import { useDispatch } from "react-redux";
import axios from "axios";
import { baseUrl } from "../../utils/constant";
import { popUpBoxTrigger } from "../../features/message/popUpSlice";
import AddTrackAlbum from "../../pages/album/AddTrackAlbum";

const AlbumHeading = () => {
    const navigate = useNavigate();
    const { albumId } = useParams();
    const dispatch = useDispatch();
    const [isOpenManage, setIsOpenManage] = useState(false);
    const [deleteAlbumDrop, setDeleteAlbumDrop] = useState(false);
    const [isAddTrackOpen, setIsAddTrackOpen] = useState(false);
    const [albumData, setAlbumData] = useState([]);


    function handleClick() {
      navigate("/album/list");
    }

    const handleCloseAddTrackDropDown = () => {
      setIsAddTrackOpen(false);
    }

    useEffect(() => {

      const token = localStorage.getItem("token");
      const getPlaylistData = async () => {
        try {
          const getPlaylist = await axios.get(`${baseUrl}artist/album/${albumId}?filter=least_popular`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
          });
      
          const result = getPlaylist;
          console.log(result);
          if(result.data.status === 200){
            setAlbumData(result.data.data);
          }
        } catch (error) {
          console.log("Error : ", error);
        }
      }
      getPlaylistData();
  
    }, []);


    const deleteAlbumList = async () => {
      try {
        const token = localStorage.getItem("token");
        const deletePlaylist = await axios({
          method: 'POST',
          url: `${baseUrl}artist/album/delete/${albumId}`,
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });
    
        const result = deletePlaylist;
        if(result.data.status === 200){
          navigate("/album/list");
          dispatch(popUpBoxTrigger({type: "success", heading: "Success", message: "Album Delete Successfully."}));
        }
      } catch (error) {
        if(error){
          dispatch(popUpBoxTrigger({type: "error", heading: "Error", message: "oops!! Something went wrong."}));
        }
      }
    }

  return (
    <div className={`${Styles.album_heading}`}>

      {
        deleteAlbumDrop && (
          <div className={`${Styles.deleteAlbum}`}>
            <div className={`${Styles.innerDeleteAlbum}`}>
                <div className={`${Styles.heading}`}>
                    <p>Do you really want to delete this album?</p>
                </div>

                <div className={`${Styles.delete_image}`}>
                    <img src={DeleteIllustration} alt="not supported" />
                </div>

                <div className={`${Styles.buttons}`}>
                    <button onClick={deleteAlbumList}>Yes, Delete Album</button>
                    <button onClick={() => {setDeleteAlbumDrop(false); setIsOpenManage(false)}}>Skip & Continue</button>
                </div>
            </div>
          </div>
        )
      }

      <div className={`${Styles.image}`}>
        <img src={albumData.cover_image_url} alt="not supported" />
      </div>

      <div className={`${Styles.dark_layout}`}></div>


      <div className={`${Styles.album_back_manage}`}>

        <div className={`${Styles.back}`}>
          <IoMdArrowRoundBack onClick={handleClick} />
        </div>

        <div className={`${Styles.manage}`}>
          <PiDotsThreeOutlineVerticalFill onClick={() => setIsOpenManage(!isOpenManage)}/>
          { isOpenManage && (
            <div className={`${Styles.manage_dropDown}`}>
              <p onClick={() => navigate(`/album/edit/${albumId}`)} ><span><FiEdit /></span> <span>Edit</span></p>
              <p onClick={() => setDeleteAlbumDrop(!deleteAlbumDrop)}><span><MdDelete /></span> <span>Delete</span></p>            
              <p onClick={() => {setIsAddTrackOpen(true); setIsOpenManage(!isOpenManage);}}><span><IoMdAdd /></span> <span>Add Track</span></p>
            </div>
          )}
        </div>

      </div>

      <div className={`${Styles.album_description}`}>
        <div className={`${Styles.artist_info}`}>
          <div className={`${Styles.artist_image}`}>
            <img src={albumData.profile_image} alt="not supported" />
          </div>
          <div className={`${Styles.album_details}`}>
            <h3>{albumData.title}</h3>
            <p>{albumData.description}</p>
          </div>
        </div>
        <div className={`${Styles.play}`}>
          <FaPlayCircle />
        </div>
      </div>

      {isAddTrackOpen && (
        <AddTrackAlbum onClose={handleCloseAddTrackDropDown}/>
      )}

    </div>
  )
}

export default AlbumHeading
