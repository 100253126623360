import React, { useEffect, useState } from 'react';
import Styles from '../assets/css/dashboard.module.scss';
import PlaylistAlbum from '../components/dashboard/PlaylistAlbum';

import { BiSolidPlaylist, BiSolidAlbum } from "react-icons/bi";
import { LuClock7 } from "react-icons/lu";
import { FaRegHeart } from "react-icons/fa";
import { FaUsers } from "react-icons/fa";

import Map from "../components/dashboard/Map";
import { useNavigate, useParams } from 'react-router-dom';
import { baseUrl } from '../utils/constant';
import axios from "axios";
import { convertDuration } from '../utils/convertFunctions';
import ContentLoaderGif from "../assets/images/contentLoader.gif";

const Dashboard = () => {

  const navigate = useNavigate();
  const [dashboardData, setDashboardData] = useState();
  const [mapData, setMapData] = useState([]);
  const { artist } = useParams();
  const [contentLoaderAlbum, setContentLoaderAlbum] = useState('true');
  const [contentLoaderPlaylist, setContentLoaderPlaylist] = useState('true');
  const [contentLoaderTrack, setContentLoaderTrack] = useState('true');

  useEffect(() => {

    const token = localStorage.getItem("token");
    const { id, name, image } = JSON.parse(localStorage.getItem("user_data"));
    const getDashboardData = async () => {

      setContentLoaderAlbum(true);
      setContentLoaderPlaylist(true);
      setContentLoaderTrack(true);

      try {

        const getData = await axios.get(`${baseUrl}artist/dashboard`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });
        console.log("Get Data : ", getData.data);

        if (getData.data.status === 200) {
          const data = getData.data.data.dashboard;
          console.log("data : ", data);
          setContentLoaderAlbum(data.top_six_albums.length !== 0 ? true : false);
          setContentLoaderPlaylist(data.top_six_artist_playlist.length !== 0 ? true : false);
          setContentLoaderTrack(data.top_ten_music_tracks.length !== 0 ? true : false);
          setDashboardData({
            totalPlaylist: data.artist_playlists,
            totalAlbum: data.albums,
            mapData: [],
            topAlbums: data.top_six_albums,
            topPlaylist: data.top_six_artist_playlist,
            topTrackList: data.top_ten_music_tracks
          })
        }

      } catch (error) {
        console.log(error);

        if (error.response.status === 403) {
          navigate("/login");
        }
      }


      try {

        const getMapData = await axios.get(`${baseUrl}artist/dashboard-map/${id}`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        });

        if (getMapData.data.status === 200) {


          console.log(getMapData.data.data.country);

          let filterData = getMapData.data.data.country.map((data) => {
            return data.code;
          })
          setMapData(filterData);
        }

      } catch (error) {
        console.log(error);
      }
    }
    getDashboardData();
  }, []);

  return (
    <div className={`${Styles.dashboard}`}>

      <div className={`${Styles.dashboard_heading}`}>
        <h2>Dashboard</h2>
      </div>

      {/* Title & Tracks */}
      <div className={`${Styles.tile_tracks}`}>

        <div className={`${Styles.tile}`}>
          <div className={`${Styles.tile_details} ${Styles.playList}`} onClick={() => { navigate("/playlist/list") }}>
            <h3>Total Playlists</h3>
            <p>{dashboardData?.totalPlaylist.toString().length == 1 ? `0${dashboardData?.totalPlaylist}` : dashboardData?.totalPlaylist}</p>
            <BiSolidPlaylist />
          </div>
          <div className={`${Styles.tile_details}`} onClick={() => { navigate("/album/list") }}>
            <h3>Total Albums</h3>
            <p>{dashboardData?.totalAlbum.toString().length == 1 ? `0${dashboardData?.totalAlbum}` : dashboardData?.totalAlbum}</p>
            <BiSolidAlbum />
          </div>
        </div>

        <div className={`${Styles.map}`}>
          <div className={`${Styles.heading}`}>
            <h3>Map</h3>
          </div>

          {/* <div className={`${Styles.map_show}`}>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3671.9994743775424!2d72.54158617603662!3d23.023791516281385!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e84e7f65f7889%3A0xb5b0fd7f4ba97b0a!2sTapas%20Elegance!5e0!3m2!1sen!2sin!4v1723785545463!5m2!1sen!2sin" allowFullScreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title='song_view'></iframe>
          </div> */}

          <div className={`${Styles.map_show}`}>
            <Map assignData={mapData} />
          </div>
        </div>
      </div>


      <div className={`${Styles.album_playlist}`}>

        {/* album */}
        <div className={`${Styles.album_list}`}>
          <h3>Top Albums</h3>

          <div className={`${Styles.albums}`}>
            {
              dashboardData?.topAlbums.length === 0 &&
              <div className={`${Styles.dataNotFound}`}>
                {
                  contentLoaderAlbum &&
                  <img height="40" width="40" src={ContentLoaderGif} alt="not supported" />
                }
                {
                  !contentLoaderAlbum &&
                  <p>Data not found</p>
                }
              </div>
            }

            {
              dashboardData?.topAlbums.map((data, index) => {
                return (
                  <PlaylistAlbum
                    key={index}
                    navigation={`/album/view/${data.id}`}
                    img={data.profile_image}
                    name={data.title}
                    totalTrack={data.total_music_tracks}
                  />
                )
              })
            }

          </div>

        </div>

        {/* playlist */}
        <div className={`${Styles.playlists}`}>
          <h3>Top Playlists</h3>
          <div className={`${Styles.playlist}`}>
            {
              dashboardData?.topPlaylist.length === 0 &&
              <div className={`${Styles.dataNotFound}`}>
                {
                  contentLoaderPlaylist &&
                  <img height="40" width="40" src={ContentLoaderGif} alt="not supported" />
                }
                {
                  !contentLoaderPlaylist &&
                  <p>Data not found</p>
                }
              </div>
            }
            {
              dashboardData?.topPlaylist.map((data, index) => {
                return (
                  <PlaylistAlbum
                    navigation={`/playlist/view/${data.id}`}
                    img={data.profile_image}
                    name={data.title}
                    totalTrack={data.total_music_tracks}
                  />
                )
              })
            }
          </div>
        </div>

      </div>

      <div className={`${Styles.top_track}`}>
        <div className={`${Styles.heading}`}>
          <h3>Top 10 Track Lists</h3>
        </div>

        <div className={`${Styles.track_table}`}>
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>Track Picture</th>
                <th>Play</th>
                <th>Duration</th>
                <th>Like</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>

              {
                dashboardData?.topTrackList.length === 0 &&
                <tr className={`${Styles.dataNotFound}`}>
                  <td colSpan="7">
                  <div className={`${Styles.trackDataNotFound}`}>
                    {
                      contentLoaderTrack &&
                      <img height="40" width="40" src={ContentLoaderGif} alt="not supported" />
                    }
                    {
                      !contentLoaderTrack &&
                      <p>Data not found</p>
                    }
                  </div>
                  </td>
                </tr>
              }

              {
                dashboardData?.topTrackList.map((data, index) => {
                  return (
                    <tr key={data.id}>
                      <td>{index + 1}</td>
                      <td className={`${Styles.image_name}`}>
                        <div className={`${Styles.image}`}>
                          <img src={data.cover_image} alt="Track Picture1" />
                        </div>
                        <div className={`${Styles.track_name}`}>
                          <p>{data.title}</p>
                        </div>
                      </td>
                      <td className={`${Styles.play}`}>
                        <div className={`${Styles.play_div}`}>
                          <FaUsers />
                          <p>{data.play_count}</p>
                        </div>
                      </td>
                      <td className={`${Styles.duration}`}>
                        <div className={`${Styles.duration_div}`}>
                          <LuClock7 />
                          <p>{convertDuration(data.track_duration)}</p>
                        </div>
                      </td>

                      <td className={`${Styles.like}`}>
                        <div className={`${Styles.like_div}`}>
                          <FaRegHeart />
                          <p>{data.total_likes}</p>
                        </div>
                      </td>
                      <td>{data.status === 1 ? "Publish" : "Unpublish"}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Dashboard
