import React from 'react';
import Styles from "../../assets/css/auth/artist/approvalPending.module.scss";
import { MdOutlinePendingActions } from "react-icons/md";
import { FaLongArrowAltRight } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';

const ApprovalPending = () => {
    const navigate = useNavigate();
    return (
        <div className={`${Styles.approvalPending}`}>
        <div className={`${Styles.main}`}>
            <div className={`${Styles.pendingLogo}`}>
                <MdOutlinePendingActions />
            </div>
            <div className={`${Styles.description}`}>
                <h1>Approval Pending</h1>
                <p>Your profile will first undergo a thorough review by an admin, who will assess all the necessary details, and once everything meets the required standards, the profile will be approved and made fully accessible.</p>
                <button onClick={() => navigate("/login")}>Back to login <FaLongArrowAltRight /></button>
            </div>
        </div>
        </div>
    )
}

export default ApprovalPending
